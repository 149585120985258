import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import { useHistory} from 'react-router-dom'
import * as Yup from "yup"
import {Formik} from 'formik';
const useStyles = makeStyles((theme) => ({
        root: {
              
              marginTop: theme.spacing(3),
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
        },
        form:{
          width: '100%',
          marginTop: theme.spacing(1)
        },
        submit: {
          margin: theme.spacing(3, 0, 2),
       
        },
      }));
//page สมัครสมาชิก
export default function Register() {

    // /api/user/getId
    const validationSchema = Yup.object({
        email: Yup.string("Enter your email")
        .email("Enter a valid email")
        .required("Email is required"),
        password: Yup.string("")
        // .min(8, "Password must contain at least 8 characters")
        .required("Enter your password")
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\\$%\\^&\\*\\.\\-])(?=.{8,})/,
            "8 Characters, 1 Uppercase, 1 Lowercase, 1 Number ,1 special Character"
          ),
        confirmPassword: Yup.string("Enter your password")
        .required("Confirm your password")
        .oneOf([Yup.ref("password")], "Password does not match"),
        name:Yup.string("").required("Require"),
        surname:Yup.string("").required("Require"),
        tel:Yup.string("")
        .matches(/^(?=.*[0-9])(?=.{1,15})/,"กรอกเฉพาะตัวเลข เท่านั้น")
        .required("Require")
        .max(15,"ความยาวสูงสุด 15 ตัวเลข"),
        company:Yup.string("").required("Require")

    })
    const classes = useStyles();
    const history =  useHistory();
    const logo = require('../Logo/Logo_SAS-LOG-01.png')
    const [err,setErr] = React.useState('')
    return (
    <div className={classes.root} style={{marginBottom:"200px"}}>
        <Formik
         initialValues={{
            email: '',
            password: '',
            confirmPassword:'',
            name:'',
            surname:'',
            tel:'',
            company:''
          }}
         validationSchema={validationSchema}
         onSubmit={ (values) => {
             console.log(values.email)
            fetch(`/api/user/getId/${values.email}`)
            .then(res=>res.json())
            .then(res=>{
                console.log(res)
                if(!(res==='not found')){
                    console.log("email dup")
                    setErr('Email ที่ใช้ซ้ำ กรุณาลอง Email อื่น')
                }else{
                    fetch('/api/user/register', {
                    method: 'POST', 
                    body: JSON.stringify({'email':values.email,'password':values.password,'name':values.name,'surname':values.surname,'tel':values.tel,'company':values.company}), 
                    headers: new Headers({
                    'Content-Type': 'application/json'
                    })
                    }).then(res =>{
                        console.log(res)
                    })
                    .then(window.alert("ทำการสมัครสมาชิกเรียบร้อย โปรดตรวจสอบที่ email เพื่อ active account"))
                    .then(
                        history.push('/login')
                    )
                }
            })
            }
        }
        >
    {({
            values,
            touched,
            errors,
            handleChange,
            handleBlur,
            handleSubmit

    }) => (
        <form onSubmit={handleSubmit} className={classes.root} Validate autoComplete="off">
            <img src={logo} style={{width:'400px'}} alt='logo'/>
            {!(err==="")?<div style={{color:'red',marginBottom:'50px'}}>{err}</div>:''}
            <div style={{width:'300px',marginTop:'-50px'}}>
            <TextField
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    id="email"
                    label="Email Address"
                    name="email"
                    required
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={touched.email ? errors.email : ""}
                    error={touched.email && Boolean(errors.email)}
                />
            <TextField
         
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    required
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={touched.password ? errors.password : ""}
                    error={touched.password && Boolean(errors.password)}
                    
                />
            <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    required
                    name="confirmPassword"
                    label="Confirm Password"
                    type="password"
                    id="confirmPassword"
                    onChange={handleChange}
                    value={values.confirmPassword}
                    onBlur={handleBlur}
                    helperText={touched.confirmPassword ? errors.confirmPassword : ""}
                    error={touched.confirmPassword && Boolean(errors.confirmPassword)}
                />
               <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    required
                    name="name"
                    label="ชื่อ"
                    type="name"
                    id="name"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={touched.name ? errors.name : ""}
                    error={touched.name&& Boolean(errors.name)}
                />
                  <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    required
                    name="surname"
                    label="นามสกุล"
                    type="surname"
                    id="surname"
                    value={values.surname}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={touched.surname ? errors.surname : ""}
                    error={touched.surname && Boolean(errors.surname)}
                />
                  <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    required
                    name="tel"
                    label="เบอร์โทรติดต่อ"
                    type="number"
                    id="tel"
                    value={values.tel}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={touched.tel ? errors.tel : ""}
                    error={touched.tel && Boolean(errors.tel)}
                />
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="company"
                    label="ชื่อบริษัท"
                    type="company"
                    id="company"
                    value={values.company}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={touched.company ? errors.company : ""}
                    error={touched.company && Boolean(errors.company)}
                />
            <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                >
                ลงทะเบียน
            </Button>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                <Link href="/login" variant="body2">
                        เข้าสู่ระบบ
                </Link>
                </Grid>
            </Grid>
            </div>
        </form>)}
        </Formik>
    </div>
        );
    
}