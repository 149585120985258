import React, { useEffect, useContext } from 'react';
import history from './history';
import Context from '../context';

//ฟังชั่นสำหรับการตรวจสอบ authentication สำหรับการเข้าถึง
const AuthCheck = (props) => {
  const context = useContext(Context)

  useEffect(() => {    
    if(context.authObj.isAuthenticated()) {
      context.handleUserLogin()
      context.handleUserAddProfile(context.authObj.profile)
      // console.log("authcheck login ");
      
      history.replace('/evaluate')
    }
    else {
      context.handleUserLogout()
      context.handleUserRemoveProfile()
      // console.log("authcheck");
      // console.log(context.authState);
      // history.push('/login') 
      setTimeout(() => { history.push('/login') }, 100);
//      history.replace('/')
    }
  }, [])

  // Redirect to another page; don't need to show anything
  return <></>
}

export default AuthCheck;
