import React ,{useEffect,useState,useContext}from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button'
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { useHistory} from 'react-router-dom'
import Context from '../context';

import {Formik} from 'formik';
import { Typography } from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
        root: {
              
              marginTop: theme.spacing(8),
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
        },
        form:{
          width: '100%',
          marginTop: theme.spacing(1)
        },
        submit: {
          margin: theme.spacing(3, 0, 2),
       
        },
      }));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

//page แสดงข้อมูลส่วนตัว
export default function Profile(props) {
    // const validationSchema = Yup.object({
    //     email: Yup.string("Enter your email")
    //     .email("Enter a valid email")
    //     .required("Email is required"),
    //     password: Yup.string("")
    //     .min(8, "Password must contain at least 8 characters")
    //     .required("Enter your password")
    //     .matches(
    //         /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*\.\-])(?=.{8,})/,
    //         "8 Characters, 1 Uppercase, 1 Lowercase, 1 Number ,1 special Character"
    //       ),
    //     confirmPassword: Yup.string("Enter your password")
    //     .required("Confirm your password")
    //     .oneOf([Yup.ref("password")], "Password does not match")
    // })
    // console.log(props)
    const classes = useStyles();
    const history =  useHistory();
    const context = useContext(Context)
    const logo = require('../Logo/Logo_SAS-LOG-01.png')
    const [data, setData] = useState({data:{}})
    const profile = {name:'',surname:'',tel:'',company:''}
    const [isAdmin,setIsadmin] = useState(false)
    const [open, setOpen] = useState(false);
    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setOpen(false);
    };
    const handleClose2 = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setChangeRoleSuccess(false);
    };
    const handleClose3 = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setChangeRoleErr(false);
    };
    const fetchData = async() => {
      await fetch(`/api/user/findUserProfile/${props.history.location.state.id}`)
        .then(res=>res.json())
        .then(res=>{
            // profile.email = res.user.email
            profile.name = res.user.name
            profile.surname = res.user.surname
            profile.tel = res.user.tel
            profile.company = res.user.company
            //console.log(res)
        })
        setData(
            {
                data:profile
            }
        )
        setIsadmin(context.authObj.profile.role === 'admin')
       
      };
      // console.log(data)
    useEffect(() => {
        fetchData();
    }, [])
    const [ChangeRoleSuccess,setChangeRoleSuccess] = useState(false)
    const [ChangeRoleErr,setChangeRoleErr] = useState(false)

    const [inputEmailtosetAdmin,setinputEmail] = useState('');
    const changerole = () =>{
      fetch('/api/user/changeRolebyadmin', {
        method: 'PUT',
        body: JSON.stringify({email:inputEmailtosetAdmin}), 
        // body: JSON.stringify({'email':values.email,'password':values.password,'name':values.name,'surname':values.surname,'tel':values.tel,'company':values.company}), 
        headers: new Headers({
        'Content-Type': 'application/json'
        })
        }).then(res=>res.json())
        .then(res=>{
          res.user?(
              setChangeRoleSuccess(true)
            ):(
              setChangeRoleErr(true)
            )
        })
    }
    const handleOnemailChange = (e) =>{
      setinputEmail(e.target.value)
    }
    return (
    <div className={classes.root} style={{marginBottom:"200px"}}>
        {inputEmailtosetAdmin}
        <Formik
        enableReinitialize={true}
        //  initialValues={{
        //     // email: '',
        //     // password: '',
        //     // confirmPassword:'',
        //     name:data.data.name,
        //     surname:profile.surname,
        //     tel: profile.tel,
        //     company:profile.company
        //   }}
        initialValues = {data.data}
        //  validationSchema={validationSchema}
         onSubmit={ (values) => { 
            fetch('/api/user/profileUpdate', {
            method: 'PUT',
            body: JSON.stringify({'id':props.history.location.state.id,'name':values.name,'surname':values.surname,'tel':values.tel,'company':values.company}), 
            // body: JSON.stringify({'email':values.email,'password':values.password,'name':values.name,'surname':values.surname,'tel':values.tel,'company':values.company}), 
            headers: new Headers({
            'Content-Type': 'application/json'
            })
            }).then(setOpen(true))
        }
        }
        >
    {({
            values,
            touched,
            errors,
            handleChange,
            handleBlur,
            handleSubmit
    }) => (
        <form onSubmit={handleSubmit} className={classes.root} noValidate autoComplete="off">
               <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    name="name"
                    // label="ชื่อ"
                    type="name"
                    id="name"
                    value={values.name||''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText="ชื่อ"
                />
                  <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    name="surname"
                    // label="นามสกุล"
                    type="surname"
                    id="surname"
                    value={values.surname||''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText="นามสกุล"
                />
                  <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    name="tel"
                    // label="เบอร์โทรติดต่อ"
                    type="tel"
                    id="tel" 
                    value={values.tel||''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText="เบอรืโทรติดต่อ"
                />
                
                <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    name="company"
                    // label="ชื่อบริษัท"
                    type="company"
                    id="company"
                    value={values.company||''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText="ชื่อบริษัท"
                />
            <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                >
                บันทึก
            </Button>
          
        </form>)}
        </Formik>
      {isAdmin?<div style={{marginRight:'100px',marginLeft:'100px',marginTop:'30px'}}>
        <Typography variant="h6">Set admin role by email</Typography>
        <Typography variant="subtitle1" color="textSecondary">(ฟังชั่นนี้เห็นเฉพาะ Admin)</Typography>
        <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    name="email"
                    // label="ชื่อบริษัท"
                    type="email"
                    id="emailtosetAdmin"
                    onChange={(e)=>handleOnemailChange(e)}
                    helperText="email to set role admin"
                />
            <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    onClick={()=>changerole()}
                >
                บันทึก
            </Button>
        </div>:''}
        <Snackbar 
          open={open}
          autoHideDuration={3000}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          style={{marginTop:'100px'}}
         >
          <Alert onClose={handleClose} severity="success">
            บันทึกข้อมูลส่วนตัวเรียบร้อย
          </Alert>
        </Snackbar>

        <Snackbar 
          open={ChangeRoleSuccess}
          autoHideDuration={3000}
          onClose={handleClose2}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          style={{marginTop:'100px'}}
         >
          <Alert onClose={handleClose2} severity="success">
            อัพเดทสถานะ ของ {inputEmailtosetAdmin} เป็น admin เรียบร้อย
          </Alert>
        </Snackbar>

        <Snackbar 
          open={ChangeRoleErr}
          autoHideDuration={3000}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          style={{marginTop:'100px'}}
         >
          <Alert onClose={handleClose3} severity="error">
            ไม่พบ email ในระบบ โปรดตรวจสอบอีกครั้ง
          </Alert>
        </Snackbar>

    </div>
        );
    
}