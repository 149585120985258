import React,{useContext,useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { useHistory } from 'react-router-dom';
import Context from '../context';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
// import { Redirect } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

export default function Navbar(props) {
  const classes = useStyles();
  const context = useContext(Context);
  // const [link,setLink] = useState('/login')
  React.useEffect(() => {
    if (context.authObj) {
      context.authObj.getProfile()
      // .then(profile => {console.log(context.authObj)})
    }
    /*if(localStorage.getItem('id_token')){
      setLink('/evaluate')
    }*/
  }, [])
    let history = useHistory();
    const ShowLogout = (props) => {
    const context = useContext(Context);
    const profile = context.profileState
    let isAdmin = profile.role === 'admin';
    const [anchorEl,setanchorEl] = useState(null)
    const handleClose =(e)=>{
      setanchorEl(null)
    }

    

    // const anchorRef = React.useRef(null);
    const handleClick =(event)=>{
      // setanchorEl(event.currentTarget)
      history.push('/listQuestion')
    }
    // console.log(profile)
    return (<>
        {(isAdmin)?
        <Button style={{backgroundColor:'#CD7864',boxShadow:'0px 4px 4px rgba(0, 0, 0, 0.25)'}} onClick={handleClick} color="inherit">Admin Function</Button>:""}
        <Menu
        
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={()=>{
         history.push('/listQuestion')
        }}>
        <Typography >เพิ่มคำถาม</Typography></MenuItem>
        
      </Menu>
      <img src={require('./user.png')} alt="" style={{width:'30px'}} />
      
      <div style={{}}>
        <Typography onClick={()=>{console.log(profile._id)}} style={{padding:'13px',paddingBottom:'0px',justifyContent:'center',alignItems:'center'}}> {profile.email}</Typography>
        <Button style={{padding:'13px',paddingTop:'0px',justifyContent:'center',alignItems:'center',textDecorationLine:'underline'}} onClick={() => {
            history.push('/Profile', { id:profile._id})
          }} color="inherit">ข้อมูลส่วนตัว
        </Button>
      </div>
        
        <Button style={{padding:'6px',justifyContent:'center',alignItems:'center'}} onClick={() => context.handleUserLogout()} color="inherit">
          Log out
          <ExitToAppIcon/>
        </Button>
      
    </>)
}

const ShowLogin = (props) => {
  return (<>
    <Button style={{paddingTop:'3px'}}
     onClick={() => {
            history.push('/login')
          }}
     color="inherit">Log in</Button>
  </>)
}
const [test,setTest] = React.useState('#EC5231')

// React.useEffect(function setupListener() {
//   const checkScrollTop = () => {
//     if (window.pageYOffset > 100){
//         setTest('#EC5231')
//     } else{
//         setTest('transparent')
//     }
// };
//   window.addEventListener('scroll', checkScrollTop)
// return function cleanupListener() {
//   window.removeEventListener('scroll', checkScrollTop)
// }
// })


  return (
    <div className={classes.root} style={{position:"sticky",top:'0',zIndex:999,minWidth:'970px'}}>
      <AppBar style={{background: '#EC5231',height:'90px',backgroundColor:test}} position="static" >
        <Toolbar >
          <IconButton style={{ backgroundColor: 'transparent' }} onClick={()=>{history.push('/evaluate')}} edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
            <img  src={require('../Logo/Logo_SAS-LOG-03.png')} alt="" style={{width:'150px'}} />
          </IconButton>
          
       <div className={classes.title}>
          {context.authState?
            <div>
              <Button  href="https://www.squat.in.th/services/logservertesting/" style={{fontSize:17}} color="inherit">ข้อมูลมาตรฐาน</Button>
              <Button style={{fontSize:17}}  onClick={() => {
                      history.push('/evaluate')
                    }} color="inherit">ประเมินผลิตภัณฑ์</Button>
            </div>
            :
            <div>
            <Button href="https://www.squat.in.th/services/logservertesting/" style={{fontSize:17}} color="inherit">ข้อมูลมาตรฐาน</Button>
            </div>
          }
       </div>   

          {
            context.authState?
            <ShowLogout/>
            :
            <ShowLogin/>
          }

        </Toolbar>

      </AppBar>
    </div>
  );
}