import React, { useEffect } from 'react';
import { useState } from 'react';


function LabelPage(props) {
    // const api_key = "AIzaSyCNbocUsUvqc880vqNQ5VjSa7a5bYMWJm8"
    const [files,setFile] = useState(null)
    const [b64files,setFileB64] = useState('')
    const [resp,setres] = useState("")

   const handleSelectFile = (event)=>{
       const reader = new FileReader();
       reader.onload = () => {
           if(reader.readyState === 2){
                setFile(reader.result);
                console.log(reader.result)
                upload(reader.result)
           }
       }
       try {
           reader.readAsDataURL(event.target.files[0])
       } catch (error) {
       }
       //console.log(event.target.files[0])

    }
    const handleUploadFile = () =>{
        console.log({"data":files})
        fetch("/vision",{
            method: 'POST',
            headers: new Headers({
            'Content-Type': 'application/json'}),
            body:JSON.stringify({"data":files})
        })
        .then(response => response.json())
        .then(res => setres(res))
        
    }
    // const str = b64files.split(',');

    const  upload= (base64Encode)=>{
        console.log(base64Encode)
        setFileB64(base64Encode)
    }
    const showLog=()=>{
        var res = resp.split('\n');
        var array = [];
        res.map(result => {
            array.push(result.split(' : '));
            return 0
        })
        console.log(array)
        console.log(res)
    }
    useEffect(()=>{
        if(resp!==""){
            showLog()
        }
    })
    const name='',model='',type='',CPU='',HDD='',Mem='',Eth='',EPs='',CC='',DS='',IR='',Company = "";
    // const schema ={
    //     name:name,
    //     model:model,
    //     type:type,
    //     Spec:{
    //         CPU:CPU,
    //         Harddisk:HDD,
    //         Memory:Mem,
    //         Ethernet:Eth,
    //         EPS:EPs,
    //         Current_Counter:CC,
    //         Device_Support:DS,
    //         Input_Rating:IR
    //     },
    //     Company:Company
    // }

    return (
        <div style={{padding:'30px'}}>
            <input type="file" onChange={handleSelectFile}></input>
            <div style={{padding:'30px',display:'flex'}}>
                <div style={{marginRight:'5px'}}>
                    <div>
                        <p>Preview Image</p>
                    
                        {files?
                        <img src={files} style={{border:'1px solid black',width:"500px",height:'500px'}} alt=""></img>:
                        <div style={{border:'1px solid black',backgroundColor:'gray',width:"500px",height:'500px',display:'flex',justifyContent:'center',alignItems:'center'}}>
                            PREVIEW IMAGE
                        </div>}
                    </div>
                </div>
                {
                    <div style={{}}>
                        <p>label detail</p>
                        <div style={{border:'1px solid black',height:'500px',width:'720px'}}>
                            <div>
                                {resp}
                            </div>
                        </div>
                    </div>
                }
            </div>
                <button onClick={handleUploadFile}>ประมวลผล</button>
        </div>
    );
}

export default LabelPage;
