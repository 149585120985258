import React from 'react';
import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import { useHistory} from 'react-router-dom'
//import Context from '../context';

//style
const useStyles = makeStyles((theme) => ({
    root: {
          marginTop: theme.spacing(3),
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          fontFamily:[
            'Kanit', 'sans-serif'
          ],
          
    },
    form:{
      width: '100%',
      marginTop: theme.spacing(1),
      fontFamily:[
        'Kanit', 'sans-serif'
      ]
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
      fontFamily:[
        'Kanit', 'sans-serif'
      ]
   
    },
  }));

  //page กดลืมรหัสผ่าน
  export default function ForgetPassPage() {
      const classes = useStyles();
      const logo = require('../Logo/Logo_SAS-LOG-01.png')
      const [email,setEmail] = useState('');
      const [password,setPass] = useState('');
      const [isSended,setIsSend] = useState(false)
      const [err,setErr] = useState('');
      const values='';
      const history =  useHistory();
      function handleSubmit(event){
        event.preventDefault();
        if(email!==''){
          fetch(`/api/user/getId/${email}`)
          .then(res=>res.json())
          .then(res=>{
            if(res!=='not found'){
              console.log('go reset pass')
              fetch(`/api/user/forgetPass/${email}`).then(
                setErr('email สำหรับ reset password ถูกส่งไปแล้ว')
              )
              .then(setIsSend(true))
              .then(window.alert("email สำหรับ reset password ได้ถูกส่งไปเป็นที่เรียบร้อย"))
              .then(
                        history.push('/login')
                    )
            }else{
              setErr('ไม่พบ Email นี้ในระบบ')
            }
          })
        }else{
          setErr('กรุณาระบุ email เพื่อทำการ Reset Password')
        }
        
        

      }
          function handleChangeEmail(event){
        setEmail(event.target.value)
      }
      function handleChangePass(event){
        setPass(event.target.value)
      }
      
      return (
            
              <div className={classes.root} style={{height:'100%'}}>
                <form 
                    onSubmit={handleSubmit}
                    className={classes.root} noValidate autoComplete="off">
                    <img src={logo} style={{width:'400px'}} alt='logo'/>
                
                <div style={{width:'300px',marginTop:'-50px'}}>
                <h4 style={{color:(err==='กรุณาระบุ email เพื่อทำการ Reset Password'||'ไม่พบ Email นี้ในระบบ')?'red':'green'}}>{err}</h4>
                <h5 style={{color:'#404040',marginBottom:-10}} htmlFor='email'>กรุณาระบุ email เพื่อทำการ Reset Password</h5>
                <TextField onChange={handleChangeEmail}
                        style={{fontFamily:[
                            'Kanit', 'sans-serif'
                        ],width:'300px'
                      }}
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        value={values.email}
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        />
                
                     <div style={{display:"flex"}}>
                        <Button
                        disabled={isSended}
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        style={{width:'330px',
                        borderRadius:'20px',
                        padding:'10px'}}
                        >
                        ยืนยัน
                        </Button>
                       
                    </div>    
                        <Grid container spacing={3}>
                       
                        <Grid item>
                            <Link style={{fontFamily:[
                            'Kanit', 'sans-serif'
                        ]}} href="/register" variant="body2">
                            ลงทะเบียนสมาชิกใหม่
                            </Link>
                            
                        </Grid>
                        </Grid>
                        </div>
                    </form>
            </div>
        );
    
}
