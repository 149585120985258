 import React from 'react'
 import { makeStyles ,withStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepConnector from '@material-ui/core/StepConnector';
import StepButton from '@material-ui/core/StepButton';
import Button from '@material-ui/core/Button';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Check from '@material-ui/icons/Check';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import StepLabel from '@material-ui/core/StepLabel';
import ArrowDropDownRoundedIcon from '@material-ui/icons/ArrowDropDownRounded';
import ArrowDropUpRoundedIcon from '@material-ui/icons/ArrowDropUpRounded';

//style
const useStyles = makeStyles((theme) => ({
    root: {
          
          margin: theme.spacing(3),
          display: 'flex',
          flexDirection: 'column',
        //   backgroundColor:'#F6F6F6',
          padding:'30px'
         // alignItems: 'center',
    },
    form:{
      width: '15rem',
      display: 'flex',
      flexDirection: 'column',
    
     
    },
    btn: {
        '& > *': {
          margin: theme.spacing(1),
        },
      },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    backButton: {
        marginRight: theme.spacing(1),
      },
      instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
      },
      line: {
        borderColor: '#eaeaf0',
        borderTopWidth: 3,
        borderRadius: 1,
      }
  }));
  
  //pageหน้าตอนทำ แบบประเมินตนเอง
 export default function Assesment(props) {
    const classes = useStyles();
    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
      }
      const [open, setOpen] = React.useState(false);
      const [open2, setOpen2] = React.useState(false);
      const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        
        setOpen(false);
      };
      const handleClose2 = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpen2(false);
      };


    const [type, setType] = React.useState('label');
    var data = []
    var data2 = []
    const [treedata2,setTreedata2] = React.useState({ treeData: []})
    const [treedata,setTreedata] = React.useState({treeData: []})
    const handleTypeChange = (event, newType) => {
        setType(newType);
      };

    let standbyData = []
    let standbyCollapse = []
    const [arrLabel,setArrLabel] = React.useState([])
    const [arrManual,setArrManual] = React.useState([])

    const traverse = (obj) => {
        for(let i = 0;i<obj.length;i++){
            var tmp = obj[i]
            standbyData.push(obj[i])
            standbyCollapse.push(false)
            if(tmp.children){
                traverse(tmp.children)
            }
        }
   }
   const traverseTosetHead = (tree,head) => {
    var index = head
    // console.log(head)
    for(let i = 0;i<tree.length;i++){
        var tmp = tree[i]
        var header = (tree[i].questionSchema.head+"").split('.')
        header[0] = index
        // console.log(header.join('.'))
        tree[i].questionSchema.head = header.join('.')
        if(tmp.children){
            traverseTosetHead(tmp.children ,index)
        }
    }
   }

    const fetchDataLabel = async () => {
            await fetch('/LabelquestionGroupList')
            .then(res=>res.json())
            .then(j=>{
                j.map((r,index)=>{
                    traverseTosetHead(r.question,index+1)
                    data.push(r)
                })
            })
            setTreedata({ 
                treeData : data
              })
          };
    // console.log(arrLabel)

    const fetchDataManual = async () => {
        await fetch('/ManualquestionGroupList')
        .then(res=>res.json())
        .then(j=>{
            j.map((r,index)=>{
                // console.log(index)
                traverseTosetHead(r.question,index+1)
                data2.push(r)
            })
        })
        setTreedata2({ 
            treeData : data2
          })
      };

    React.useEffect(() => {
          fetchDataLabel();
          fetchDataManual();
        }, [])
    React.useEffect(()=>{
        //console.log(treedata.treeData[0])
        treedata.treeData.map(r=>{
            //console.log(r.question)
            traverse(r.question)
        })
        setArrLabel(standbyData)
        setArrCollapseLabel(standbyCollapse)
        standbyData = []
        standbyCollapse = []
        treedata2.treeData.map(r=>{
            //console.log(r.question)
            traverse(r.question)
        })
        setArrManual(standbyData)
        setArrCollapseManual(standbyCollapse)
    },[treedata,treedata2])

    // React.useEffect(() => {
    //     console.log(arrLabel)
    //     console.log(arrManual)
    //     console.log(arrCollapseLabel)
    //     console.log(arrCollapseManual)
    //   }, [arrLabel,arrManual])
    
  

    const [page,setPage] = React.useState(0)

    const [files,setFile] = React.useState(null)
    const [b64files,setFileB64] = React.useState('')
    const handleSelectFile = (event)=>{
        const reader = new FileReader();
        reader.onload = () => {
            if(reader.readyState === 2){
                 setFile(reader.result);
                //  console.log(reader.result)
                 upload(reader.result)
            }
        }
        try {
               reader.readAsDataURL(event.target.files[0])
        } catch (error) {
            console.log('file not found')
        }
        // console.log(event.target.files[0])
      
     }
     const  upload= (base64Encode)=>{
        // console.log(base64Encode)
        setFileB64(base64Encode)
    }

    function getSteps() {
        return ['กรอกข้อมูลผลิตภัณฑ์', 'แบบประเมินฉลาก', 'แบบประเมินคู่มือ'];
    }
    //steper---------
    const [activeStep, setActiveStep] = React.useState(0);
    const steps = getSteps();
  
    const handleNext = () => {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
  
    const handleBack = () => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
  
    const handleReset = () => {
      setActiveStep(0);
    };
    //----------------
    //form------------
    const [productName,setProductName] = React.useState('')
    const [model,setModel] = React.useState('')
    const [inputType, setInputType] = React.useState('');
    const handleChangeInputTypeChange = (event) => {
        setInputType(event.target.value);
    };
    const [prodType,setProdType] = React.useState('');
    const handleProdTypeChange = (event) =>{
        setProdType(event.target.value);
    }
    const formData = {
        productName : productName,
        model : model,
        inputType : inputType,
        productType : prodType,
        productImg : b64files
    }
   
    //---------------------
    const form = ()=>{
        return (
            <div className={classes.root}>
                <form className={classes.form} style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}  noValidate autoComplete="off">
                    <div style={{display:'flex',flexDirection:'column',justifyContent:'space-between'}}>
                        <TextField  id="standard-basic" 
                                    label="ชื่อผลิดภัณฑ์"
                                    placeholder='Log server A' 
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    
                                    style={{width:'300px'}}
                                    onChange={(e)=>{
                                        setProductName(e.target.value)
                                    }}
                                    value={productName}
                        />
                        <TextField  id="standard-basic"
                                    label="รุ่น"
                                    placeholder='model x'
                                    style={{width:'300px'}}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={(e)=>{
                                        setModel(e.target.value)
                                    }}
                                    value={model}
                        />
                        <FormControl component="fieldset" style={{marginTop:'20px'}}>
                            <FormLabel component="legend">วิธีรับข้อมูล</FormLabel>
                            <RadioGroup aria-label="gender" name="gender1" value={inputType} onChange={handleChangeInputTypeChange}>
                                <FormControlLabel value="syslog UDP" control={<Radio />} label="syslog UDP" />
                                <FormControlLabel value="syslog TCP" control={<Radio />} label="syslog TCP" />
                                <FormControlLabel value="syslog UDP,syslog TCP" control={<Radio />} label="syslog UDP และ syslog TCP" />
                                <FormControlLabel value="other" control={<Radio />} label="อื่นๆ" />
                            </RadioGroup>
                        </FormControl>
                        <FormControl component="fieldset" style={{marginTop:'20px'}}>
                            <FormLabel component="legend">ประเภทผลิตภัณฑ์</FormLabel>
                            <RadioGroup aria-label="type" name="type" value={prodType} onChange={handleProdTypeChange} >
                                <div  style={{width:'500px'}}>
                                <FormControlLabel value="ฮาร์ดแวร์และซอฟต์แวร์ Log server" control={<Radio />} label="ฮาร์ดแวร์และซอฟต์แวร์ Log server" style={{marginRight:'50px'}}/>
                                <FormControlLabel value="ซอฟต์แวร์ Log server" control={<Radio />} label="ซอฟต์แวร์ Log server" /></div>
                            </RadioGroup>
                        </FormControl>
                    </div>
                    <div>
                        <div>Preview Image
                            {
                                files?
                                <img src={files} style={{width:"250px"}} alt=""></img>
                                :
                                <div style={{backgroundColor:'gray',width:'250px',height:'250px',display:'flex',justifyContent:'center',alignItems:'center'}}>
                                    PREVIEW IMAGE
                                </div>
                            }
                        </div>
                        <label htmlFor="file">รูปภาพผลิตภัณฑ์</label>
                        <input type="file" id='file' onChange={handleSelectFile}></input>    
                    </div>
                </form>
            </div>
        )
    }
    
    const [click,setClick] = React.useState(true)
    const [pop,setPop] = React.useState(false)
    const [isEdit,setEdit] = React.useState(false)
    const bgColorStyleHead = (question,index) =>{
        var color = '#4AADFB'
        if(question.head.toString().replaceAll('.','').length===1){
            color = '#4AADFB'
        }
        if(question.head.toString().replaceAll('.','').length===2){
            color = '#BF9E9E'
        }
        if(question.head.toString().replaceAll('.','').length===3){
            color = '#FFFCB5'
        }
        
        return {
            backgroundColor:color,
            padding:'20px',
            borderBottomLeftRadius:'10px',
            borderTopLeftRadius:'10px',
            boxShadow:'0px 4px 4px rgba(0, 0, 0, 0.25)'

        }
    }
    const bgColorStyleBody = (question,index) =>{
        var color = '#ADD5F4'
        if(question.head.toString().replaceAll('.','').length===1){
            color = '#ADD5F4'
        }
        if(question.head.toString().replaceAll('.','').length===2){
            color = '#E0B8B8'
        }
        if(question.head.toString().replaceAll('.','').length===3){
            color = '#FFFDCC'
        }
        
        return {
            backgroundColor:color,
            marginLeft:(question.head.toString().replaceAll('.','').length===1)?'00px':`${question.head.toString().replaceAll('.','').length*20}px`,
            borderBottomLeftRadius:'10px',
            borderTopLeftRadius:'10px',
            boxShadow:'0px 4px 4px rgba(0, 0, 0, 0.25)',
            marginBottom:'5px',
        }
    }
    const [arrCollapseLabel,setArrCollapseLabel] = React.useState([])
    const [arrCollapseManual,setArrCollapseManual] = React.useState([])
    const label = ()=>{
        return (
            <div  className={classes.root} >
                <div>
                    {/* <h1>Label</h1> */}
                    <Button
                    variant="contained"
                    
                    style={{width:'200px',marginBottom:'20px',backgroundColor:"#CA5A45",color:'white'}}
                    onClick={()=>{setPop(!pop)
                        setSpec('')
                        setFileLabel(null)
                    }}>ประเมินด้วยฉลาก</Button>
                 </div>
                 {
                    pop? labelScan():''
                 }
                
                {
                    arrLabel.map((data,index)=>{
                        // console.log(data)
                        const question = data.questionSchema
                        // if(data.children!==undefined)
                        //     console.log(data.children.length)
                        //console.log(question.head.toString().replaceAll('.','').length + " "+question.head)
                        return (
                         //4AADFB
                        <div  id={question.head} key={data.title+"-"+index}
                            style={bgColorStyleBody(question,index)
                        }>
                            <div  style={
                                bgColorStyleHead(question,index)
                            }>
                                {/* <p>{question.head}</p> */}
                                <p>{question.head+" "+question.question}</p>
                                <p style={{color:"#555555"}}>{question.desc}</p>
                            </div>
                        
                            {(!data.children)?
                            (<FormControl component="fieldset" style={{marginTop:'5px',padding:'5px'}}>{/* มี child ไหม ถ้าไม่มี render ส่วนนี้*/}
                                <RadioGroup aria-label="ltype" name="ltype" value={data.questionSchema.ans}
                                 onChange={(e)=>{
                                  data.questionSchema.ans = e.target.value
                                  data.questionSchema.isAns = true
                                  setClick(!click)
                                 }}>
                                     <div>
                                        <FormControlLabel value="true" control={<Radio />} label="ใช่" />
                                        {data.questionSchema.ans==="true"?<TextField style={{backgroundColor:'white',borderTopRightRadius:'3px',borderTopLeftRadius:'3px'}} type='text' onFocus={()=>{setEdit(true)}} value={data.questionSchema.txtInput} onChange={(e)=>{
                                            question.txtInput = e.target.value
                                            // console.log('here'+data.questionSchema.txtInput)
                                            setClick(!click)
                                        }}></TextField>:''}
                                    </div>
                                    <FormControlLabel value="false" control={<Radio />} label="ไม่ใช่" />
                                </RadioGroup>
                            </FormControl>)
                            :
                            data.children.length>0?
                            <Button
                            color='secondary'
                             onClick={()=>{
                                {/*มี child ขนาดมากกว่า 0 ไหม ถ้าใช่ render ส่วนนี้*/}
                                var tmp = arrCollapseLabel[index]
                                tmp = !arrCollapseLabel[index]
                                // console.log(tmp)
                                setArrCollapseLabel([
                                    ...arrCollapseLabel.slice(0,index),
                                    tmp,
                                    ...arrCollapseLabel.slice(index+1)
                                ])
                                arrLabel.map(r=>{
                                    // console.log("head = "+question.head+"----------")
                                    var x = r.questionSchema.head.substring(0,question.head.length)
                                    // console.log("str = "+x+"==========="+r.questionSchema.head)
                                    if(x.includes(question.head)&&question.head!==r.questionSchema.head){
                                        // console.log("x = "+x+" question head = "+question.head)
                                        // console.log(r.questionSchema.head)
                                        var disp = document.getElementById(r.questionSchema.head)
                                        if(question.head.length < r.questionSchema.head.length){
                                            if(arrCollapseLabel[index]===true){
                                                document.getElementById(r.questionSchema.head).style.display = "block";
                                            }else{
                                                document.getElementById(r.questionSchema.head).style.display = "none";
                                            }
                                        }
                                        else{
                                            if(disp.style.display === "none"){
                                                document.getElementById(r.questionSchema.head).style.display = "block";
                                            }else{
                                                document.getElementById(r.questionSchema.head).style.display = "none";
                                            }
                                        }
                                        
                                    }
                                })
                            }}>{arrCollapseLabel[index]?<div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                                                            <ArrowDropDownRoundedIcon fontSize="large"/>
                                                            <label style={{fontSize:'16px'}}>Expand</label>
                                                        </div>:
                                                        <div  style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                                                            <ArrowDropUpRoundedIcon fontSize="large"/>
                                                            <label style={{fontSize:'16px'}}>Collapse</label>
                                                        </div>}
                            </Button>:
                                (<FormControl component="fieldset" style={{marginTop:'5px',padding:'5px'}}>{/* child ไหม ถ้าไม่มี render ส่วนนี้*/}
                                <RadioGroup aria-label="ltype" name="ltype" value={data.questionSchema.ans}
                                onChange={(e)=>{
                                data.questionSchema.ans = e.target.value
                                data.questionSchema.isAns = true
                                setClick(!click)
                                }}>
                                    <div>
                                        <FormControlLabel value="true" control={<Radio />} label="ใช่" />
                                        {data.questionSchema.ans==="true"?<TextField style={{backgroundColor:'white',borderTopRightRadius:'3px',borderTopLeftRadius:'3px'}} type='text' onFocus={()=>{setEdit(true)}} value={data.questionSchema.txtInput} onChange={(e)=>{
                                            question.txtInput = e.target.value
                                            // console.log('here'+data.questionSchema.txtInput)
                                            setClick(!click)
                                        }}></TextField>:''}
                                    </div>
                                    <FormControlLabel value="false" control={<Radio />} label="ไม่ใช่" />
                                </RadioGroup>
                            </FormControl>)
                            }
                        </div>
                        )
                    })
                }
            </div>
        )
    }

    const manual = ()=>{
        return (
            <div  className={classes.root} >
                {/* <h1>manual</h1> */}
                {
                    arrManual.map((data,index)=>{
                        
                        const question = data.questionSchema
                        return (
                        <div id={question.head} key={data.title+"-"+index}
                        style={bgColorStyleBody(question,index)}
                            /*style={{
                            backgroundColor:(index%2===0?'#ADD5F4':`#E0B8B8`),
                            marginLeft:(question.head.toString().replaceAll('.','').length===1)?'00px':`${question.head.toString().replaceAll('.','').length*20}px`,
                            borderBottomLeftRadius:'10px',
                            borderTopLeftRadius:'10px',
                            boxShadow:'0px 4px 4px rgba(0, 0, 0, 0.25)'
                            }}*/
                            >
                            <div
                             style={bgColorStyleHead(question,index)} 
                            /*style={{
                                backgroundColor:index%2===0?'#4AADFB':'#BF9E9E',
                            padding:'20px',
                            borderBottomLeftRadius:'10px',
                            borderTopLeftRadius:'10px',
                            boxShadow:'0px 4px 4px rgba(0, 0, 0, 0.25)'
                        }}*/
                        >
                                <p>{question.head+" "+question.question}</p>
                                <p style={{color:"#555555"}}>{question.desc}</p>
                            </div>
                            {!data.children?(<FormControl key={index} component="fieldset" style={{marginTop:'5px',padding:'5px'}}>
                                <RadioGroup aria-label="mtype" name="mtype" value={data.questionSchema.ans}
                                 onChange={(e)=>{
                                    data.questionSchema.ans = e.target.value
                                    data.questionSchema.isAns = true
                                    // console.log(data.questionSchema.ans)
                                    setClick(!click)
                                }}>
                                    <div>
                                    <FormControlLabel value="true" control={<Radio />} label="ใช่" />
                                        {data.questionSchema.ans==="true"?<TextField style={{backgroundColor:'white',borderTopRightRadius:'3px',borderTopLeftRadius:'3px'}} type='text' value={data.questionSchema.txtInput} onChange={(e)=>{
                                                data.questionSchema.txtInput = e.target.value
                                                setClick(!click)
                                                setisCallPass(false)
                                        }}></TextField>:''}
                                    </div>
                                    <FormControlLabel value="false" control={<Radio />} label="ไม่ใช่" />
                                </RadioGroup>
                            </FormControl>):
                             data.children.length>0?
                            <Button
                            color="secondary"
                            onClick={()=>{
                                
                                var tmp = arrCollapseManual[index]
                                tmp = !arrCollapseManual[index]
                                // console.log(tmp)
                                setArrCollapseManual([
                                    ...arrCollapseManual.slice(0,index),
                                    tmp,
                                    ...arrCollapseManual.slice(index+1)
                                ])
                                arrManual.map(r=>{
                                    // console.log("head = "+question.head+"----------")
                                    var x = r.questionSchema.head.substring(0,question.head.length)
                                    // console.log("str = "+x+"==========="+r.questionSchema.head)
                                    // document.getElementById("1.1").style.display = "none";
                                    // console.log(x)
                                    if(x.includes(question.head)&&question.head!==r.questionSchema.head){
                                        // console.log("x = "+x+" question head = "+question.head)
                                        // console.log(r.questionSchema.head)
                                        var disp = document.getElementById(r.questionSchema.head)
                                        if(question.head.length < r.questionSchema.head.length){
                                            if(arrCollapseManual[index]===true){
                                                document.getElementById(r.questionSchema.head).style.display = "block";
                                            }else{
                                                document.getElementById(r.questionSchema.head).style.display = "none";
                                            }
                                        }
                                        else{
                                            if(disp.style.display === "none"){
                                                document.getElementById(r.questionSchema.head).style.display = "block";
                                            }else{
                                                document.getElementById(r.questionSchema.head).style.display = "none";
                                            }
                                        }
                                    }
                                })
                            }}>
                                {arrCollapseManual[index]?<div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                                                            <ArrowDropDownRoundedIcon fontSize="large"/>
                                                            <label style={{fontSize:'16px'}}>Expand</label>
                                                        </div>:
                                                        <div  style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                                                            <ArrowDropUpRoundedIcon fontSize="large"/>
                                                            <label style={{fontSize:'16px'}}>Collapse</label>
                                                        </div>}
                                
                                </Button>:
                                 (<FormControl component="fieldset" style={{marginTop:'5px',padding:'5px'}}>{/* child ไหม ถ้าไม่มี render ส่วนนี้*/}
                                 <RadioGroup aria-label="ltype" name="ltype" value={data.questionSchema.ans}
                                 onChange={(e)=>{
                                 data.questionSchema.ans = e.target.value
                                 data.questionSchema.isAns = true
                                 setClick(!click)
                                 }}>
                                     <div>
                                         <FormControlLabel value="true" control={<Radio />} label="ใช่" />
                                         {data.questionSchema.ans==="true"?<TextField style={{backgroundColor:'white',borderTopRightRadius:'3px',borderTopLeftRadius:'3px'}} type='text' onFocus={()=>{setEdit(true)}} value={data.questionSchema.txtInput} onChange={(e)=>{
                                             question.txtInput = e.target.value
                                             // console.log('here'+data.questionSchema.txtInput)
                                             setClick(!click)
                                         }}></TextField>:''}
                                     </div>
                                     <FormControlLabel value="false" control={<Radio />} label="ไม่ใช่" />
                                 </RadioGroup>
                             </FormControl>)
                                }
                        </div>
                        )
                    })
                }
            </div>
        )
    }
    const [filesLabel,setFileLabel] = React.useState(null)
    const [b64filesLabel,setFileB64Label] = React.useState('')
    const [resp,setres] = React.useState("")
    const handleSelectFileLabel = (event)=>{
        const reader = new FileReader();
        reader.onload = () => {
            if(reader.readyState === 2){
                 setFileLabel(reader.result);
                 //console.log(reader.result)
                 //upload(reader.result)
                 if(!isFirst)
                    setIsFirst(true)
            }
        }
        try {
            reader.readAsDataURL(event.target.files[0])
        } catch (error) {
        }
     }
     const [arr,setArr] = React.useState('')

     const handleUploadFile = () =>{
        //console.log({"data":filesLabel})
        fetch("/vision",{
            method: 'POST',
            headers: new Headers({
            'Content-Type': 'application/json'}),
            body:JSON.stringify({"data":filesLabel})
        })
        .then(response => response.json())
        .then(res => {
            setres(res.payload)
            console.log(res)
            if(!(res.error)){
                var res2 = res.payload.split('\n');
                var array = [];
                res2.map(result => {
                    array.push(result.split(' : '));
                    return 0
                })
                console.log(array)
                setArr(array)
                setIsFirst(true)
                setOpen(true)
            }else{
                setOpen2(true)
            }
            // window.alert("ประมวลผลเสร็จสิ้น")
        })
    }
    const  uploadLabel = (base64Encode)=>{
        // console.log(base64Encode)
        setFileB64Label(base64Encode)
    }

    const [spec,setSpec] = React.useState({})
    
    const [show,setShow] = React.useState(false)

    const labelScan = () =>{
        return (
            <div style={{backgroundColor:'#C79286',marginTop:'60px',padding:'30px',position:'absolute',left:'30px',top:'280px',borderRadius:'5px',zIndex:900,boxShadow:'0px 4px 4px rgba(0, 0, 0, 0.25)'}}>
            <input style ={{backgroundColor:'white',padding:'10px' ,borderRadius:'5px',marginRight:'10px'}} type="file" onChange={handleSelectFileLabel}></input>
            <Button style = {{position:'absolute',right:"30px",backgroundColor:'#C63F3F',color:'white'}} onClick={()=>{setPop(!pop)}}  variant="contained" >ปิด</Button>
            <Button variant="contained" color="primary" onClick={handleUploadFile}>ประมวลผล</Button>
            <h4 style={{backgroundColor:'#FFFDCC',marginTop:10}}>** หมายเหตุ การตรวจหาคำในรูปภาพจะต้องมี เครื่องหมาย ":" คั่น ระหว่าง keyword และ value มิฉะนั้นระบบจะไม่สามารถตรวจหาคำได้
                <Button style={{ backgroundColor: 'transparent' }} disableRipple={true} variant="text" color="secondary" onClick={()=>setShow(!show)}>กดเพื่อดูตัวอย่างของฉลากที่ใช้ในการแสกนได้</Button>
            </h4>
            {show?
                <img onClick={()=>setShow(!show)} src={require('./ExampleLabel.png')} style={{position:'absolute',top:30,border:'1px solid #CC5C42',width:"400px"}} alt=""></img>
                :
                ""
            }
            <div style={{padding:'30px',paddingTop:'0px',display:'flex'}} onClick={()=>{if(show)setShow(!show)}}>
                <div style={{marginRight:'5px'}}>
                    <div>
                        <p>Preview Image</p>
                        {filesLabel?
                        <img src={filesLabel} style={{border:'1px solid #CC5C42',width:"400px",height:'400px'}} alt=""></img>:
                        <div style={{border:'1px solid #CC5C42',backgroundColor:'#C4C4C4',width:"400px",height:'400px',display:'flex',justifyContent:'center',alignItems:'center'}}>
                            PREVIEW IMAGE
                        </div>}
                    </div>
                </div>
                {
                    <div style={{}}>
                        <p>label detail</p>
                        <div style={{border:'1px solid #CC5C42',height:'400px',width:'720px',backgroundColor:'white'}}>
                            <div style={{marginTop:'10px',padding:'10px'}}>
                                 <table>
                                    <tbody >
                                    <tr>
                                        <td align="right" style={{paddingRight:'20px'}}>Product Name</td> 
                                        <td>{spec.productname}</td>
                                    </tr>
                                    <tr>
                                        <td align="right" style={{paddingRight:'20px'}}>Model</td> 
                                        <td>{spec.model}</td>
                                    </tr>
                                    <tr>
                                        <td align="right" style={{paddingRight:'20px'}}>Cpu</td> 
                                        <td>{spec.cpu}</td>
                                    </tr>
                                    <tr>
                                        <td align="right" style={{paddingRight:'20px'}}>Harddisk</td> 
                                        <td>{spec.hdd}</td>
                                    </tr>
                                    <tr>
                                        <td align="right" style={{paddingRight:'20px'}}>Memory</td> 
                                        <td>{spec.memory}</td>
                                    </tr>
                                    <tr>
                                        <td align="right" style={{paddingRight:'20px'}}>Ethernet</td> 
                                        <td>{spec.ethernet}</td>
                                    </tr>
                                    <tr>
                                        <td align="right" style={{paddingRight:'20px'}}>Current Counter</td> 
                                        <td>{spec.counter}</td>
                                    </tr>
                                    <tr>
                                        <td align="right" style={{paddingRight:'20px'}}>Device Support</td> 
                                        <td>{spec.devicesupport}</td>
                                    </tr>
                                    <tr>
                                        <td align="right" style={{paddingRight:'20px'}}>Input Rate</td> 
                                        <td>{spec.inputrating}</td>
                                    </tr>
                                    <tr>
                                        <td align="right" style={{paddingRight:'20px'}}>Company Name</td> 
                                        <td>{spec.companyname}</td>
                                    </tr>
                                    <tr>
                                        <td align="right" style={{paddingRight:'20px'}}>Address</td> 
                                        <td>{spec.address}</td>
                                    </tr>
                                    <tr>
                                        <td align="right" style={{paddingRight:'20px'}}>Tel.</td>
                                        <td>{spec.tel}</td>
                                    </tr>
                                    <tr>
                                        <td align="right" style={{paddingRight:'20px'}}>Email</td> 
                                        <td>{spec.email}</td>
                                    </tr>
                                    </tbody>
                                 </table>

                            </div>
                        </div>
                    </div>
                }
            </div>
                {/* <Button variant="contained" color="primary" onClick={handleUploadFile}>ประมวลผล</Button> */}
        </div>
        );
    }


    const [stepCounter,setStep] = React.useState(
        [false,false,false]
    )
    const handleStep = (step) => () => {
        setActiveStep(step);
        setPage(step)
        
      };
    const [isFirst,setIsFirst] = React.useState(true) 
    const [isCalPass,setisCallPass] = React.useState(true)
    const [isAllAnswer,setIsAllAnswer] = React.useState(false)

    const [formCounter,setformlCounter] = React.useState('')
    const [labelCounter,setLabelCounter] = React.useState('')
    const [manualCounter,setManualCounter] = React.useState('')

    React.useEffect(()=>{
        //console.log('state change')
        if(resp!==""){
            localStorage.setItem('data',JSON.stringify(arr))
            console.log(resp)
        }
        if(localStorage.getItem('data')){
            var user = JSON.parse(localStorage.getItem('data'))
            //console.log(user)
            let modelsArr = [];
            let property = ["product_name", "model", "cpu", "hardisk", "memory", "ethernet", "current_counter", "device_support", "input_rating", "company_name", "address", "tel.", "email"]
            let test = []
            let attributeArr = ['', '', '', '', '', '', '', '', '', '', '', '', '']
            let dat = []
            var array = [];
           var typeProd = []
            
            if(user[0]&&isFirst){
                user.map(t => {
                    t.map(x=>{
                        dat.push(x.split(': '));
                    })
                    return 0
                })

                user.map(res => {
                    if (res.length > 1 && res.length < 3){
                        modelsArr.push(res)
                    }else{
                        let t = res[0].split(':')
                        if (t.length > 1 && t.length < 3){
                            modelsArr.push(t)
                        }
                    }
                    if(res.length===1){
                        let re = new RegExp('[ก-ฮ]\\.[๑-๙]');
                        var myArray = res[0].match(re);
                        if(myArray!==null){
                          console.log(myArray)
                          typeProd.push(myArray+"")
                        }
                    }

                })
                    console.log(modelsArr)
                modelsArr.map(res => {
                        res[0] = res[0].toLowerCase()
                        res[0] = res[0].replace(" ", "_");
                    })
                modelsArr.map(res => {
                        if(res===undefined){
                    
                    }else{
                        test.push(res)
                    }
                    })
                for (let i = 0; i < property.length; i++) {
                    let prop = property[i];
                    
                    for (let j = 0; j < test.length; j++) {
                            if(modelsArr[j]===undefined){
                            break;
                        }else if (prop === modelsArr[j][0]) {
                            attributeArr[i] = modelsArr[j][1]
                            break;
                        }else{
                            attributeArr[i] = null
                            }
                        }
                }
                let JSONspecObj = {
                    productname: attributeArr[0],
                    model: attributeArr[1],
                    cpu: attributeArr[2],
                    hdd: attributeArr[3],
                    memory: attributeArr[4],
                    ethernet: attributeArr[5],
                    counter: attributeArr[6],
                    devicesupport: attributeArr[7],
                    inputrating: attributeArr[8],
                    companyname: attributeArr[9],
                    address: attributeArr[10],
                    tel: attributeArr[11],
                    email: attributeArr[12],
                    type : typeProd
                  }
                    console.log(attributeArr)
                    console.log(JSONspecObj)
                    setSpec(JSONspecObj)
                    setIsFirst(!isFirst)
            }
            //  console.log(spec)
            //  console.log(isCalPass)
             if(spec.productname&&!isFirst&&!isEdit){
                 if(isCalPass){
                     console.log(spec)
                   
                     checkLabelScan()
                    //setisCallPass(false)
                 }
                 console.log('check')
                localStorage.removeItem('data')
             }
        }
        var a = false
        var b = false
        var c = false
        if(formData){
            var len = 0
            var maxLen = 5
            if(formData.productName!=='')
                len +=1
            if(formData.inputType!=='')
                len +=1
            if(formData.model!=='')
                len +=1
            if(formData.productImg!=='')
                len +=1
            if(formData.productType!=='')
                len +=1
            setformlCounter(len+"/"+maxLen)
            if(len===maxLen){
                setformlCounter("complete")
                c = true
                handleComplete(0)
            }else{
                c = false
                handleUnComplete(0)
            }
        }
        if(arrLabel){
          var lenchild =  arrLabel.filter((arr) => !arr.children).length
          var haveChildButempty = arrLabel.filter((arr) => arr.children?arr.children.length===0:'').length
          var len =  arrLabel.filter((arr) => arr.questionSchema.isAns!==false).length
          let str = len+"/"+(lenchild+haveChildButempty)
        //   console.log(str)
          setLabelCounter(str)
          if(len===(lenchild+haveChildButempty)){
                // console.log('ตอบคำถามครบแล้ว')
                a = true
                setLabelCounter('complete')
                handleComplete(1)
                // setIsAllAnswer(true)
          }else{
                // console.log('ยังตอบไม่ครบ')
                a = false
                handleUnComplete(1)
                // setIsAllAnswer(false)
          }
        }
        if(arrManual){
            var lenchild =  arrManual.filter((arr) => !arr.children).length
            var haveChildButempty = arrManual.filter((arr) => arr.children?arr.children.length===0:'').length
            var len =  arrManual.filter((arr) => arr.questionSchema.isAns!==false).length
            let str = len+"/"+(lenchild+haveChildButempty)
            // console.log(str)
            setManualCounter(str)
            if(len===(lenchild+haveChildButempty)){
                //   console.log('ตอบคำถามครบแล้ว')
                  b = true
                  setManualCounter('complete')
                  handleComplete(2)
                //   setIsAllAnswer(true)
            }else{
                //   console.log('ยังตอบไม่ครบ')
                  b = false
                  handleUnComplete(2)
                //   setIsAllAnswer(false)
            }
          }
        //   console.log(a&&b)
          if(a&&b&&c){
            setIsAllAnswer(true)
          }
       
    })
    
    const pages = [form(),label(),manual()]
    const checkLabelScan = ()=>{
        for(let i = 0 ; i< 13;i++){
            arrLabel.map(data=>{
                const question = data.questionSchema
                 if(question.keyWord==='product'){
                     if(spec.productname===null)
                         question.ans = 'false'
                     else
                         question.ans = 'true'
                    question.txtInput = spec.productname
                    question.isAns = true
                 }
                 if(question.keyWord==='model'){
                     if(spec.model===null)
                         question.ans = 'false'
                     else
                         question.ans = 'true'
                    question.txtInput = spec.model
                    question.isAns = true
                 }
                 if(question.keyWord==='cpu'){
                     if(spec.cpu===null)
                         question.ans = 'false'
                     else
                         question.ans = 'true'
                    question.txtInput = spec.cpu
                    question.isAns = true
                 }
                 if(question.keyWord==='hdd'){
                     if(spec.hdd===null)
                         question.ans = 'false'
                     else
                         question.ans = 'true'
                     question.txtInput = spec.hdd
                     question.isAns = true
                 }
                 if(question.keyWord==='ethernet'){
                     if(spec.ethernet===null)
                         question.ans = 'false'
                     else
                         question.ans = 'true'
                     question.txtInput = spec.ethernet
                     question.isAns = true
                 }
                 if(question.keyWord==='Company'){
                     if(spec.companyname===null)
                         question.ans = 'false'
                     else
                         question.ans = 'true'
                    question.txtInput = spec.companyname
                    question.isAns = true
                 }
                 if(question.keyWord==='currentcounter'){
                     if(spec.counter===null)
                         question.ans = 'false'
                     else
                         question.ans = 'true'
                    question.txtInput = spec.counter
                    question.isAns = true
                 }
                 if(question.keyWord==='devicesupport'){
                     if(spec.devicesupport===null)
                         question.ans = 'false'
                     else
                         question.ans = 'true'
                    question.txtInput = spec.devicesupport
                    question.isAns = true
                 }
                 if(question.keyWord==='inputrate'){
                     if(spec.inputrating===null)
                         question.ans = 'false'
                     else
                         question.ans = 'true'
                    question.txtInput = spec.inputrating
                    question.isAns = true
                 }
                 if(question.keyWord==='mem'){
                     if(spec.memory===null)
                         question.ans = 'false'
                     else
                         question.ans = 'true'
                    question.txtInput = spec.memory
                    question.isAns = true
                 }
                 if(question.keyWord==='type'){
                     if(spec.type.length===0)
                         question.ans = 'false'
                     else
                         question.ans = 'true'
                    question.txtInput = spec.type
                    question.isAns = true
                 }
              }
            )
        }
        
    }
    const QontoConnector = withStyles({
        alternativeLabel: {
          top: 10,
          left: 'calc(-50% + 16px)',
          right: 'calc(50% + 16px)',
        },
        active: {
          '& $line': {
            // borderColor: '#784af4',
          },
        },
        completed: {
          '& $line': {
            borderColor: '#784af4',
          },
        },
        line: {
          borderColor: '#eaeaf0',
          borderTopWidth: 10,
          borderRadius: 1,
        },
      })(StepConnector);
      const useQontoStepIconStyles = makeStyles({
        root: {
          color: '#eaeaf0',
          display: 'flex',
          height: 22,
         
        },
        active: {
          color: '#784af4',
        },
        circle: {
          width: 8,
          height: 8,
          borderRadius: '50%',
          backgroundColor: 'currentColor',
        },
        completed: {
          color: '#784af4',
          zIndex: 1,
          fontSize: 18,
        },
      });
      
      function QontoStepIcon(props) {
        const classes = useQontoStepIconStyles();
        const { active, completed } = props;
      
        return (
          <div
            className={clsx(classes.root, {
              [classes.active]: active,
            })}
          >
          </div>
        );
      }

      QontoStepIcon.propTypes = {
        active: PropTypes.bool,
        completed: PropTypes.bool,
      };

      const scrollTop = () =>{
        window.scrollTo({top: 0, behavior: 'smooth'});
     };
     const scrollBot = () =>{
        //window.scrollTo({bottom: 0, behavior: 'smooth'});
        divRref.current.scrollIntoView({ behavior: 'smooth' });
     };
      const [goTo,setGoto] = React.useState('go to Bottom')
    //   const checkScrollTop = () => {
    //         if (window.pageYOffset > 400){
    //             setGoto('go to Top')
    //         } else if (window.pageYOffset <= 400){
    //             setGoto('go to Bottom')
    //         }
    //   };
    //   window.addEventListener('scroll', checkScrollTop)
    React.useEffect(function setupListener() {
        const checkScrollTop = () => {
          if (window.pageYOffset > 400){
              setGoto('go to Top')
          } else if (window.pageYOffset <= 400){
              setGoto('go to Bottom')
          }
      };
      window.addEventListener('scroll', checkScrollTop)
      return function cleanupListener() {
        window.removeEventListener('scroll', checkScrollTop)
      }
    })
      const divRref = React.useRef(null);
      
    const [completed, setCompleted] = React.useState({});
    const handleComplete = (index) => {
        const newCompleted = completed;
        newCompleted[index] = true;
        setCompleted(newCompleted);
       
    };
    const handleUnComplete = (index) => {
        const newCompleted = completed;
        newCompleted[index] = false;
        setCompleted(newCompleted);
       
    };
    function Connector({ index, active, completed, disabled }) {
        return <QontoConnector />
    }

     return (
         <div key={'x'} style={{marginBottom:'200px'}}>
             <Button variant="contained" color="primary" onClick={goTo==='go to Bottom'?scrollBot:scrollTop} style={{ position:'fixed',width:'20px',height:'30px',bottom:'100px',right:'30px'}}>
                 {goTo==='go to Top'?
                     <ArrowUpwardIcon style={{width:'20px',height:'20px'}}/>
                 :
                    <ArrowDownwardIcon style={{width:'20px',height:'20px'}}/>
                 }
             </Button>
             
             <Stepper style={{paddingLeft:'150px',paddingRight:'150px',minWidth:'1134px'}} activeStep={activeStep}  nonLinear  connector={<QontoConnector/>}>
                {steps.map((label,index) => (
                <Step completed={completed[index]} key={label+"-"+index}  style={{padding:'30px',borderRadius:'20px',backgroundColor:activeStep===index?'#A1A1A1':('#E8E8E8'),boxShadow:'0px 4px 4px rgba(0, 0, 0, 0.25)'}}>
                    <StepButton onClick={handleStep(index)}>
                        <h2 >{label}</h2>
                        {/* <StepLabel>{label}</StepLabel> */}
                    </StepButton>
                        <StepLabel StepIconComponent={QontoStepIcon}><p style={{display:'flex',justifyContent:'flex-end',margin:'0'}}>{index===0?formCounter:(index===1?labelCounter:manualCounter)}</p></StepLabel > 
                </Step>
                ))}
            </Stepper>
            <div ref={divRref} className={classes.btn} style={{paddingLeft:'40px',paddingRight:'40px'}}>
                {page===0?'':<Button variant="contained" onClick={()=>{setPage((page-1)%3)
                                                    setActiveStep((page-1)%3)}}>ย้อนกลับ</Button>}
                {page===2?
                <Button variant="contained" color="secondary" disabled={!isAllAnswer} onClick={()=>{
                    props.history.push('/report',{labelAnswer:arrLabel,manualAnswer:arrManual,labelTree:treedata,manualTree:treedata2,formData:formData})
                    console.log(arrLabel)
                    }}>
                    เสร็จสิ้นการประเมิน
                </Button>
                :
                <Button variant="contained" color="primary" onClick={()=>{
                    setPage((page+1)%3)
                    setActiveStep((page+1)%3)
                    }}>ถัดไป
                </Button>}
            </div>
             {pages[page]}
             <div ref={divRref} className={classes.btn} style={{paddingLeft:'40px',paddingRight:'40px'}}>
                {page===0?'':<Button variant="contained" onClick={()=>{setPage((page-1)%3)
                                                    setActiveStep((page-1)%3)}}>ย้อนกลับ</Button>}
                {page===2?
                <Button variant="contained" color="secondary" disabled={!isAllAnswer} onClick={()=>{
                    // console.log(arrLabel)
                    props.history.push('/report',{labelAnswer:arrLabel,manualAnswer:arrManual,labelTree:treedata,manualTree:treedata2,formData:formData})
                    }}>
                    เสร็จสิ้นการประเมิน
                </Button>
                :
                <Button variant="contained" color="primary" onClick={()=>{
                    setPage((page+1)%3)
                    setActiveStep((page+1)%3)
                    }}>ถัดไป
                </Button>}
            </div>
            <Snackbar 
                open={open}
                autoHideDuration={3600}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                style={{marginTop:'100px'}}
                >
                <Alert onClose={handleClose} severity="success">
                    ประเมินผลเสร็จสิ้น
                </Alert>
            </Snackbar>
            <Snackbar 
                open={open2}
                autoHideDuration={6000}
                onClose={handleClose2}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                style={{marginTop:'100px'}}
                >
                <Alert onClose={handleClose2} severity="warning">
                    รูปภาพไม่ตรงตาม spec กรุณาตรวจสอบตัวอย่างรูปฉลากที่ใช้ได้ โดยคลิ๊ก "กดเพื่อตูตัวอย่างของฉลากที่ใช้ในการแสกน
                </Alert>
            </Snackbar>

         </div>
     )
 }
 