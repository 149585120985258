import React, {useReducer,useState}  from 'react';
import './App.css';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Context from './context';
import Auth from './utils/auth'
import * as ACTIONS from './utils/store/actions/actions';
import * as AuthReducer from './utils/store/reducers/auth_reducer';
import Routes from './route'


function App (){
   const THEME = createMuiTheme({
    typography: {
      fontFamily:[
        'Kanit', 'sans-serif'
      ]
    }
 });
 const auth = new Auth();
 const [stateAuthReducer, dispatchAuthReducer] = useReducer(AuthReducer.AuthReducer,
  AuthReducer.initialState)

const handleLogin = () => {
dispatchAuthReducer(ACTIONS.login_success())
}

const handleLogout = () => {
auth.signout();
dispatchAuthReducer(ACTIONS.login_failure())
}

const handleAddProfile = (profile) => {
dispatchAuthReducer(ACTIONS.add_profile(profile))
}

const handleRemoveProfile = () => {
dispatchAuthReducer(ACTIONS.remove_profile())
}
// *** reset profile to newState
const handleResetProfile = (newState) => {     
dispatchAuthReducer(ACTIONS.reset_profile(newState))
}

  return (
    <Context.Provider value={
      {
      
        authState: stateAuthReducer.is_authenticated,
            profileState:  stateAuthReducer.profile,
            handleUserLogin: () => handleLogin(),
            handleUserLogout: () => handleLogout(),
            handleUserAddProfile: (profile) => handleAddProfile(profile),
            handleUserRemoveProfile: () => handleRemoveProfile(),
            handleUserResetProfile: (state) => handleResetProfile(state),
            authObj: auth
      }
    }>
      <ThemeProvider theme={THEME}>
        <Routes/>
      </ThemeProvider>
    </Context.Provider>
  );
  }
export default App;
