
import React from 'react';
import Context from '../context';
import * as Yup from "yup"
import {Formik} from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import { useParams } from 'react-router-dom';
import { useHistory} from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
        
        marginTop: theme.spacing(3),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
  },
  form:{
    width: '100%',
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
 
  },
}));
//page reset password 
const Home = ()=> {
    // const [USERS,setUsers] = useState()
    const {_id} = useParams()
    const data = useParams()
    const classes = useStyles();
    const history =  useHistory();
    const logo = require('../Logo/Logo_SAS-LOG-01.png')
    // const context = useContext(Context);
    const validationSchema = Yup.object({
      password: Yup.string("")
      .min(8, "Password must contain at least 8 characters")
      .required("Enter your password")
      .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\\$%\\^&\\*\\.\\-])(?=.{8,})/,
          "8 Characters, 1 Uppercase, 1 Lowercase, 1 Number ,1 special Character"
        ),
      confirmPassword: Yup.string("Enter your password")
      .required("Confirm your password")
      .oneOf([Yup.ref("password")], "Password does not match")
  })

    const [text,setText] = React.useState('')
    const [isPass,setPass] = React.useState(false)
        return (
          
          <div className={classes.root} style={{marginBottom:"200px"}}>
                <Formik
                initialValues={{
                    password: '',
                    confirmPassword:'',
                  }}
                validationSchema={validationSchema}
                onSubmit={ (values) => {
                    console.log(_id)
                    fetch(`/api/user/resetpass/${_id}`, {
                      method: 'PUT',
                      body: JSON.stringify({'password':values.password}), 
                      headers: new Headers({
                      'Content-Type': 'application/json'
                      })
                      }).then(res=>res.json()).then(res => {
                        // if(res === 'complete'){
                        if(true){
                          setText('reset password เรียบร้อย')
                          window.alert('reset password เรียบร้อย')
                          history.push('/login')
                          setPass(true)
                        }else{
                          setText('reset password ไม่สำเร็จมีบางอย่างผิดพลาด กรุณาติดต่อเจ้าหน้าที่')
                          setPass(false)
                        }
                      })
                    }
                }
                >
            {({
                    values,
                    touched,
                    errors,
                    handleChange,
                    handleBlur,
                    handleSubmit
        
            }) => (
              
                <form onSubmit={handleSubmit} className={classes.root} noValidate autoComplete="off">
                    <img src={logo} style={{width:'400px'}} alt='logo'/>
                   
                    <div style={{width:'300px',marginTop:'-50px'}}>
                    {<div style={{color:'red'}}>{text}</div>}
                    <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            name="password"
                            label="Reset-Password"
                            type="password"
                            id="password"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={touched.password ? errors.password : ""}
                            error={touched.password && Boolean(errors.password)}
                        />
                    <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            name="confirmPassword"
                            label="Confirm Password"
                            type="password"
                            id="confirmPassword"
                            onChange={handleChange}
                            value={values.confirmPassword}
                            onBlur={handleBlur}
                            helperText={touched.confirmPassword ? errors.confirmPassword : ""}
                            error={touched.confirmPassword && Boolean(errors.confirmPassword)}
                        />
                     
                    <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            style={{borderRadius:'20px',padding:'10px'}}
                        >
                        ยืนยัน
                    </Button>
                    </div>
                </form>
                )}
                </Formik>
            </div>
        ); 
      
}
export default Home;