import React,{useState} from 'react';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button'
import SortableTree, { changeNodeAtPath,getNodeAtPath,addNodeUnderParent,removeNodeAtPath } from 'react-sortable-tree';
import 'react-sortable-tree/style.css';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { numberComparer } from '@material-ui/data-grid';
import { color } from 'd3-color';


//style
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
//-----------------------------------

//page แก้ไข้/เพิ่ม คำถาม
function AddQuestion(props) {
    // const questionSchema = {
    //     id:new Date(),
    //     question:"คำถาม1",
    //     isCheck:"",
    //     C:"",
    //     NC:""
    // }
    //console.log(props.history.location.state)
    //const [propsState,setpropsState] = useState({treeData:[props.history.location.state.data]})
   // console.log(propsState.treeData)
   const classes = useStyles();
   var data = []
   var data2 = []
    const [question, setQuestion] = useState('');
    const [desc,setDesc] = useState('');
    const [isClick,setClick] = useState(false)
    const QuestionForm=()=>{
        return(
       <div>
               <form noValidate autoComplete="off" style={{}}>
             
             <div style={{padding:'10px'}}>
              <div  style={{width:"100%"}}>
                   <TextField
                   style={{width:"40%"}}
                   id="outlined-name1"
                   label="คำถาม"
                   value={question}
                   onChange={handleChange}
                   variant="outlined"
                   />
               </div>
                   <TextField
                   style={{width:"40%"}}
                   id="outlined-name2"
                   label="desc"
                   value={desc}
                   onChange={(e)=>{setDesc(e.target.value)}}
                   variant="outlined"
                   />
               <div  style={{width:"100%"}}>
               <FormControlLabel
                   control={
                   <Checkbox
                       checked={state.checked}
                       onChange={handleCheck}
                       name="checked"
                       color="primary"
                   />
                   }
                   label="Check"
               />
               </div>
              
             </div>
           </form>
           <button onClick={addTolist}>add</button>
           </div>
        )
       }
    const [keyWords,setKeyWord] = useState('')
    const addTolist=()=>{
            var questionSchema = {
                head:'',
                question : "new Question",
                isCheck:state.checked,
                isUse:true,
                desc:"description",
                C:false,
                NC:false,
                NA:false,
                ans:true,
                isAns:false,
                txtInput:'',
                keyWord:keyWords

            }
            setTreedata({treeData:[...treedata.treeData,{title:question,questionSchema}]})
        
    }
   
    const handleChange = (event) => {
      setQuestion(event.target.value);
    };

    const [state, setState] = useState({
        checked: false,
      });
    const handleCheck= (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
        
    };
    
    // var [treedata2,setdata2] = useState({})


// function getNodeFromTree(node, nodeId) {
//     if (node.name === nodeId) {
//         return node;
//     } else if (node.children != null) {
//         var result = null;
//         for (let i = 0; result == null && i < node.children.length; i++) {
//             result = getNodeFromTree(node.children[i], nodeId);
//         }
//         return result;
//     }
//     return null;
// }

//console.log(JSON.stringify(getNodeFromTree(treedata2,'5.2')))
// function insertNodeIntoTree(node, nodeId, newNode) {
//     if (node.name === nodeId) {
//         if (newNode) {
//             node.children.push(newNode);
//         }

//     } else if (node.children != null) {
//         for (let i = 0; i < node.children.length; i++) {
//             insertNodeIntoTree(node.children[i], nodeId, newNode);
//         }
//     }
// }

/*insertNodeIntoTree(treedata2,'5',objToBePushed)
console.log(treedata2)*/
    
    const ref = React.createRef();
    const [name,setName] = useState('')
    
    const [treedata,setTreedata] = useState({treeData:[props.history.location.state.data]});
    // const [treedata,setTreedata] = useState({treeData:[]});
    //console.log(treedata.treeData)
    const handleClickSave=()=>{
      
      if(props.history.location.state.type === 'label'){
        if(props.history.location.state.id === 1){
          let resp = ''
          fetch('/LabeladdQuestion', {
            method: 'POST',
            body: JSON.stringify({'question':treedata.treeData}), 
            headers: new Headers({
            'Content-Type': 'application/json'
            })
            }).then(response => response.json())
            .then(res => {props.history.location.state.id = res.id})
            .then( setOpen(true))
     
          }
        else{
          fetch('/updateLabelQuestionGroup', {
            method: 'PUT',
            body: JSON.stringify({'id':props.history.location.state.id,'question':treedata.treeData}), 
            headers: new Headers({
            'Content-Type': 'application/json'
            })
          }).then( setOpen(true))
        }
            //props.history.location.state.id
      }else{
        if(props.history.location.state.id === 1){

        fetch('/ManualaddQuestion', {
          method: 'POST',
          body: JSON.stringify({'question':treedata.treeData}), 
          headers: new Headers({
          'Content-Type': 'application/json'
          })
          }).then( setOpen(true))
        }
        
      else{
        fetch('/updateManualQuestionGroup', {
          method: 'PUT',
          body: JSON.stringify({'id':props.history.location.state.id,'question':treedata.treeData}), 
          headers: new Headers({
          'Content-Type': 'application/json'
          })
        }).then( setOpen(true))
        }
      }
      
    }

    const handleNameChange = (e) =>{
        setName(e.target.value)
    }

    const [isInput,setIsInput] = useState(false)
    const [nodeClicked,setNodeClicked] = useState(null)
  
    const handleNodeClick = (node) => {
        setNodeClicked(node)
        setIsInput(!isInput)
      };
    const  alertNodeInfo = ({ node, path, treeIndex }) => {
        setIsInput(false)
        console.log(Object.keys(node))
        const objectString = Object.keys(node)
          .map((k) => (k === "children" ? "children: Array" : `${k}: '${node[k]}'`))
          .join(",\n   ");
        global.alert(
          "Info passed to the button generator:\n\n" +
            `node: {\n   ${objectString}\n},\n` +
            `path: [${path.join(", ")}],\n` +
            `treeIndex: ${treeIndex}`
        );
      };
      
      const [open, setOpen] = useState(false);
      const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen(false);
      };
      const fetchDataManual = async () => {
        console.log(props.history.location.state.id)
        if(props.history.location.state.id!==1){
          await fetch(`/getManualQuestion/${props.history.location.state.id}`)
          .then(res=>res.json())
          .then(j=>{
            const arr = j[0].question
            console.log(arr)
              arr.map((res,index)=>{
                  console.log(res)
                  data2.push(res)
              })
          })
          setTreedata({ 
              treeData : data2
            })
        }
      };
      const fetchDataLabel = async () => {
        console.log(props.history.location.state.id)
        if(props.history.location.state.id!==1){
          await fetch(`/getLabelQuestion/${props.history.location.state.id}`)
          .then(res=>res.json())
          .then(j=>{
            const arr = j[0].question
            console.log(arr)
              arr.map((res,index)=>{
                  console.log(index)
                  data.push(res)
              })
          })
          setTreedata({ 
              treeData : data
          })
        }
      };
      
      const [click,setChangeClick] = React.useState(true)
      React.useEffect(() => {
        if(props.history.location.state.type==='label')
          fetchDataLabel();
        else
          fetchDataManual();
      }, [])
      const mystyle = {
        color: "white",
        backgroundColor: "DodgerBlue",
        padding: "10px",
        fontFamily: "Arial"
      };
    //  console.log(treedata)
    return (
        <div ref={ref} style={{padding:30,marginBottom:'70px'}}>
        <h1>{"Group ที่ : "+props.history.location.state.group}</h1>
        {/* <button onClick={()=>{
            setClick(!isClick)
            addTolist
          }}>เพิ่มคำถาม</button> */}
          <Button variant="contained" color="primary" onClick={addTolist}>เพิ่มคำถาม</Button>
        {/* {isClick?QuestionForm():""} */}
        
        <div style={{ height:'580px' }} >
       
        <SortableTree

            maxDepth={4}
            treeData={treedata.treeData}
            onChange={treeData => {
              setTreedata({treeData})
            }}
            // scaffoldBlockPxWidth={100}
            generateNodeProps={(rowInfo) => {
              const { node, path ,treeIndex} = rowInfo;
              let num = 0
              if(rowInfo.parentNode){
                rowInfo.parentNode.children.map((r,index)=>{
                  if(rowInfo.node.questionSchema.question===r.questionSchema.question)
                    // console.log(index)
                    num = index+1
                    node.questionSchema.head = rowInfo.parentNode.questionSchema.head+"."+num
                })
              }
              if(!rowInfo.parentNode){
                node.questionSchema.head = props.history.location.state.group
              }
              //console.log( rowInfo.node.questionSchema.question+" and The "+num+" is my index ")
              // node.questionSchema.head = rowInfo.parentNode.questionSchema.head+"."+num
              return {
                title:
                  isInput && node === nodeClicked ? (
                    <form>
                      <textarea
                        style={{ fontSize: "1.1rem",maxHeight:'70px',marginRight:'10px'}}
                        value={node.questionSchema.question}
                        onChange={(event) => {
                          console.log(node.title)
                          node.title=event.target.value;
                          node.questionSchema.question = event.target.value;
                          setTreedata(() => ({
                            treeData: changeNodeAtPath({
                              treeData: treedata.treeData,
                              path,
                              getNodeKey:({ treeIndex }) =>  treeIndex,
                              newNode: node
                            })
                          }));
                        }
                      }
                        onBlur={() => {
                          setIsInput(false);
                        }}
                      />
                      <textarea
                        style={{ fontSize: "1.1rem",maxHeight:'70px',marginRight:'10px'}}
                        value={node.questionSchema.desc}
                        onChange={(event) => {
                          node.questionSchema.desc = event.target.value;
                          setTreedata(() => ({
                            treeData: changeNodeAtPath({
                              treeData: treedata.treeData,
                              path,
                              getNodeKey:({ treeIndex }) =>  treeIndex,
                              newNode: node
                            })
                          }));
                        }}
                        onBlur={() => {
                          setIsInput(false);
                        }}
                      />
                    </form>
                  ) : (
                    "ข้อ "+node.questionSchema.head+" : "+node.questionSchema.question
                  ),
                buttons: [
                <div style={{display:'flex'}}>
                  {!(rowInfo.node.children===undefined||rowInfo.node.children.length===0)?
                  <div>
                    <FormControlLabel
                    control={
                    <Checkbox
                          checked={rowInfo.node.questionSchema.isCheck}
                        onChange={()=>{
                            rowInfo.node.questionSchema.isCheck=(!rowInfo.node.questionSchema.isCheck)
                            rowInfo.node.questionSchema.ans=(!rowInfo.node.questionSchema.isCheck)
                            console.log( rowInfo.node)
                            setChangeClick(!click)
                          }}
                        name="checked"
                        color="primary"
                    />
                    }
                    label="เลือกเพื่อกำหนดเงื่อนไขเป็น OR ,ไม่เลือกเป็น AND"
                    />
                    <FormControlLabel
                    control={
                    <Checkbox
                          checked={rowInfo.node.questionSchema.isUse}
                        onChange={()=>{
                            rowInfo.node.questionSchema.isUse=(!rowInfo.node.questionSchema.isUse)
                            console.log( rowInfo.node)
                            setChangeClick(!click)
                          }}
                        name="checked"
                        color="primary"
                    />
                    }
                    label="เลือก ใช้/ไม่ใช้ ในการคำนวณ"
                    />
                     { 
                     props.history.location.state.type === 'label'?
                      <>
                        <label htmlFor="key">เลือก keyword บนฉลาก:</label>
                        <select name="key" id="key" value={node.questionSchema.keyWord} onChange={(e)=>{
                          setKeyWord(e.target.value)
                          node.questionSchema.keyWord = e.target.value
                          setChangeClick(!click)
                        }}>
                          <option value="any">ไม่มี</option>
                          <option value="product">Product Name</option>
                          <option value="model">Model</option>
                          <option value="cpu">CPU</option>
                          <option value="hdd">Harddisk</option>
                          <option value="mem">Memory</option>
                          <option value="ethernet">Ethernet</option>
                          <option value="eps">EPS</option>
                          <option value="Company">Company</option>
                          <option value="currentcounter">Current Counter</option>
                          <option value="devicesupport">Device Support</option>
                          <option value="inputrate">Input Rating</option>
                          <option value="type">ประเภท การจัดเก็บ</option>
                        </select>
                      </>
                      :
                      ''
                      }
                  </div>
                :<div>
                  <FormControlLabel
                    control={
                    <Checkbox
                          checked={rowInfo.node.questionSchema.isUse}
                        onChange={()=>{
                            rowInfo.node.questionSchema.isUse=(!rowInfo.node.questionSchema.isUse)
                            console.log( rowInfo.node)
                            setChangeClick(!click)
                          }}
                        name="checked"
                        color="primary"
                    />
                    }
                    label="เลือก ใช้/ไม่ใช้ ในการคำนวณ"
                    />
                    {
                      props.history.location.state.type === 'label'?
                      <>
                        <label name='kwLBL' id="lbl" htmlFor="key">เลือก keyword บนฉลาก:</label>
                        <select name="key" id="key" value={node.questionSchema.keyWord} onChange={(e)=>{
                          setKeyWord(e.target.value)
                          node.questionSchema.keyWord = e.target.value
                          setChangeClick(!click)
                        }}>
                          <option value="any">ไม่มี</option>
                          <option value="product">Product Name</option>
                          <option value="model">Model</option>
                          <option value="cpu">CPU</option>
                          <option value="hdd">Harddisk</option>
                          <option value="mem">Memory</option>
                          <option value="ethernet">Ethernet</option>
                          <option value="eps">EPS</option>
                          <option value="Company">Company</option>
                          <option value="currentcounter">Current Counter</option>
                          <option value="devicesupport">Device Support</option>
                          <option value="inputrate">Input Rating</option>
                          <option value="type">ประเภท การจัดเก็บ</option>
                        </select>
                      </>
                      :
                      ''
                    }
                    

                </div>
                }
                  {/* <button
                    className="btn btn-outline-success"
                    style={{
                      verticalAlign: "middle"
                    }}
                    onClick={() => {
                        console.log(rowInfo.node)
                        alertNodeInfo(rowInfo)}}
                  >
                    ℹ
                  </button> */}
                  <div style={{justifyContent:'center',marginLeft:'10px',display:'flex',alignItems:'center'}}>
                  <Button variant="contained" color="inherit"  onClick={(rowInfo)=>{
                        handleNodeClick(node);
                    }} >edit</Button>
                    <Button variant="contained" color="secondary"  onClick={(rowInfo)=>{
                        setTreedata({ 
                          treeData : removeNodeAtPath({
                                        treeData: treedata.treeData,
                                        path: path,   // You can use path from here
                                        getNodeKey: ({node: Treedata,treeIndex: number}) => {
                                              
                                            return number;
                                        },
                                        ignoreCollapsed: true,
                                      })
                          })
                    }} >remove</Button>
                  </div>
                </div>
                ],
                // onClick: () => {
                //   handleNodeClick(node);
                // },
                style:node == nodeClicked&&{
                  border: "3px solid yellow",
                }
              };
            }}
            rowHeight={90}
          />
        </div>
        {/* {treedata.treeData.map((res,index)=>{
          return (
            <div key={index} style={{backgroundColor:"gray",margin:10}}>
              {index}
              {JSON.stringify(res.title)}
            </div>)
        })} */}
        <Button style={{marginRight:'20px'}}  variant="contained" color="secondary" onClick={props.history.goBack}>Back</Button>
        <Button variant="contained" color='primary' onClick={handleClickSave}>บันทึก</Button>
         <Snackbar 
          open={open}
          autoHideDuration={1000}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          style={{marginTop:'100px'}}
         >
          <Alert onClose={handleClose} severity="success">
            Save Success
          </Alert>
        </Snackbar>
    </div>
    );
}

export default AddQuestion;