import React,{useContext} from 'react';
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Context from '../context';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';


const useStyles = makeStyles({
    table: {
      minWidth: 650,
    },
    tableRow: {
      '&:hover': {
        backgroundColor: '#BFBFBF !important'
      },
    },
    
  });


//page รายงานผลการประเมินตนเอง
function Report(props) {
    const context = useContext(Context);
    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
      }
      const [open, setOpen] = React.useState(false);
      const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen(false);
      };
    const [type, setType] = React.useState('label');
    const [company,setCompany] = React.useState('')
    const [email,setEmail] = React.useState('')
    const [LabelAnswer,setLabelAnswer] = React.useState([...props.history.location.state.labelAnswer])
    const [ManualAnswer,setManualAnswer,] = React.useState([...props.history.location.state.manualAnswer])
    const [labelTree,setLabelTree] = React.useState(props.history.location.state.labelTree)
    const [manualTree,setManualTree] = React.useState(props.history.location.state.manualTree)
    const [formData,setFormData] = React.useState(props.history.location.state.formData)

    // console.log(LabelAnswer)
    // console.log(formData)
    // console.log(email)
    const dummySet=()=>{
        setLabelAnswer('')
        setManualAnswer('')
        setLabelAnswer('')
        setManualAnswer('')
        setLabelTree('')
        setManualTree('')
        setFormData('')
    }
    const classes = useStyles();
    const ref = React.createRef();
    const handleTypeChange = (event, newType) => {
        setType(newType);
      };
    function getNodeFromTree(node, nodeId) {
        // console.log(node.question[0].questionSchema.question)
        if (node.question[0].questionSchema.question === nodeId.questionSchema.question) {
            node.question[0].questionSchema.ans = nodeId.questionSchema.ans
            return node;
        } else if (node.children != null) {
            var result = null;
            for (let i = 0; result == null && i < node.children.length; i++) {
                result = getNodeFromTree(node.children[i], nodeId);
            }
            return result;
        }
        return null;
    }
    

//     const traverse = (obj,parent) => {
//         for(let i = 0;i<obj.length;i++){
//          let tmp = obj[i]
//        console.log(tmp)
//        console.log('parent is '+parent)
       
//        if(parent){
//         //    console.log(JSON.stringify(question[0]))
//            search(question[0],parent,tmp.ans)
//            console.log("child ans: "+tmp.ans+' parent is '+parent)
//        }
//        if(tmp.children){
//            traverse(tmp.children,tmp.title)
//        }
//             //  menuArr.push(obj[i])
//      }
//    }
    // console.log(props.history.location.state.labelAnswer)
    // console.log(labelAnswer)
    const search = (tree, target , ans ,index) => {
        //console.log('searching target = '+target+', tree title '+tree.questionSchema.ans , ans)
        // console.log("ข้อที่ "+tree.questionSchema.head)
        // console.log(tree)
        // console.log(tree.title)
        // console.log(tree.questionSchema.ans)
        // console.log("คำตอบ "+ans)
        // console.log(tree.children)
        // console.log("check tree question :"+tree.questionSchema.question)
        // console.log("target :"+target)

        // if(tree.questionSchema.question === target){
        //     console.log('hello world')
        // }
        if (tree.questionSchema.question === target) {

        var treeValue = ''
        var ansValue = ''

        if(tree.questionSchema.ans==='true'||tree.questionSchema.ans===true)
            treeValue = true
        
        if(tree.questionSchema.ans==='false'||tree.questionSchema.ans===false)
            treeValue = false

        if(ans ==='true'||ans ===true)
            ansValue = true

        if(ans === 'false'||ans=== false)
            ansValue = false
        //console.log(ansValue +" and "+treeValue)
        // console.log(tree.questionSchema.isCheck)
        // console.log(tree.questionSchema.head+" index of children "+index)
        if(!tree.questionSchema.isCheck){
            tree.questionSchema.ans =  treeValue&&ansValue
        }else{
            // console.log(tree.questionSchema.head)
            // console.log(index+'searching target = '+target+', tree title '+tree.questionSchema.ans +','+ ans)
            // if(index===0){
            //     // console.log(ans)
            //     treeValue = false
            //     tree.questionSchema.ans =  false||ansValue
            // }else
                tree.questionSchema.ans =  treeValue||ansValue
        }
        return tree.ans;
      }
    //   if(tree.children){
    //         if(tree.children.length>0){
    //             console.log('hello')
    //         for(let i = 0 ; i < tree.children.length;i++){
    //             // for (const child of tree.children) {
    //             // const res = search(child, target,ans);
    //             // console.log(tree.children[i].questionSchema.head+" "+tree.children[i].title+" target = "+target+" "+ ans+" index "+i)
    //             const res = search(tree.children[i], target,ans,i);
    //             // search(tree.children[i], target,ans,i);
    //             // console.log(tree.children[i].questionSchema.head+" "+tree.children[i].title+" target = "+target+" "+ ans+" index "+i)
    //             if (res) {
    //                 console.log(tree.children[i].questionSchema.head+" "+tree.children[i].title+" target = "+target+" "+ ans+" index "+i)
    //                 return res;
    //             }
    //         }
    //     }
    // }
      if(tree.children){
        for(let i = 0 ; i < tree.children.length;i++){
        //   for (const child of tree.children) {
            // const res = search(child, target,ans);
            // console.log(tree.children[i].questionSchema.head+" "+tree.children[i].title+" target = "+target+" "+ ans+" index "+i)
            const res = search(tree.children[i], target,ans,i);
            // search(tree.children[i], target,ans,i);
            // console.log(tree.children[i].questionSchema.head+" "+tree.children[i].title+" target = "+target+" "+ ans+" index "+i)
            if (res) {
                console.log(tree.children[i].questionSchema.head+" "+tree.children[i].title+" target = "+target+" "+ ans+" index "+i)
                return res;
            }
        }
      }
    }
    const [calComplete , setComplete] = React.useState(false)

    React.useEffect(()=>{
        //---setUpLabelTree
        // console.log(props.history.location.state._id)
        if(props.history.location.state._id===undefined){
            // console.log(LabelAnswer)
            if(LabelAnswer){
                LabelAnswer.map((data,index)=>{
                    // console.log(data)
                    //console.log(labelTree.treeData)
                    for(let i = 0;i<labelTree.treeData.length;i++){
                        //console.log(labelTree.treeData[i]._id)
                        getNodeFromTree(labelTree.treeData[i],data)
                    }
                    return 0;
                })
            }
            // console.log(labelTree)
            //---setUpManualTree
            if(ManualAnswer){
                ManualAnswer.map((data,index)=>{
                    //console.log(labelTree.treeData)
                    for(let i = 0;i<manualTree.treeData.length;i++){
                        //console.log(labelTree.treeData[i]._id)
                        getNodeFromTree(manualTree.treeData[i],data)
                    }
                    return 0;
                })
            }
            // console.log(manualTree)
            //---cal label
            // console.log(labelTree)
            //console.log(labelTree)
            if(labelTree)
                labelTree.treeData.map((data,index)=>{
                    //console.log(data.question[0])
                        const traverse = (obj,parent) => {
                            // console.log(obj)
                        
                            for(let i = 0;i<obj.length;i++){
                                let tmp = obj[i]
                            // console.log(tmp)
                            // console.log('parent is '+parent)
                                
                                if(parent&&tmp.questionSchema.isUse){
                                    search(data.question[0],parent,tmp.questionSchema.ans,i)
                                //   console.log("child ans: "+tmp.questionSchema.ans+' parent is '+parent)
                                }
                                if(tmp.children){
                                    traverse(tmp.children,tmp.questionSchema.question)
                                }
                                        //  menuArr.push(obj[i])
                            }
                        }
                    for(let i = 0;i < 10 ;i++){
                        traverse(data.question)
                    }
                    return 0;
                })
            
            // console.log(labelTree)
            //cal manual
            if(manualTree)
            manualTree.treeData.map((data,index)=>{
            // console.log(data.question[0])
                    const traverse = (obj,parent) => {
                        // console.log(obj)
                    
                        for(let i = 0;i<obj.length;i++){
                            let tmp = obj[i]
                    //     console.log(tmp)
                    //      console.log('parent is '+parent)
                            
                            if(parent){
                                search(data.question[0],parent,tmp.questionSchema.ans,i)
                            //    console.log("child ans: "+tmp.questionSchema.ans+' parent is '+parent)
                            }
                            if(tmp.children){
                                traverse(tmp.children,tmp.questionSchema.question)
                            }
                                    //  menuArr.push(obj[i])
                        }
                    }
                for(let i = 0;i < 10 ;i++){
                    traverse(data.question)
                }
                return 0;
            })
            setComplete(!calComplete)
            
            // console.log(LabelAnswer)
            // console.log(labelTree)
            // console.log(manualTree)
            // console.log(ManualAnswer)
            //console.log(formData)
            //console.log(localStorage.getItem('id_token'))
            if(localStorage.getItem('id_token')){
                let comp = ''
                const id = localStorage.getItem('id_token')
                fetch(`api/user/findUserProfile/${id}`)
                    .then(res=>res.json())
                    .then(res => {
                        setCompany(res.user.company)
                        // console.log(res.user.email)
                        // setEmail(res.user.email)
                    })
            }
            window.alert("หากต้องการ \"บันทึกผลการประเมิน\" ให้ กดปุ่ม \"บันทึกผลแบบประเมินนี้\" ในหน้ารายงานผลด้วย")
        }else{
            if(localStorage.getItem('id_token')){
                const id = localStorage.getItem('id_token')
                //console.log(id)
            }
        }
        
    },[])
    const [disable,setDisableSave] = React.useState(false)
    const handleSaveReport=()=>{
        if(localStorage.getItem('id_token')){
                fetch('/SaveReport', {
                        method: 'POST',
                        body: JSON.stringify({
                            'userId' : localStorage.getItem('id_token'),
                            'userLabelAnswer': LabelAnswer,
                            'userManualAnswer' : ManualAnswer,
                            'treeLabel' : labelTree.treeData,
                            'treeManual' : manualTree.treeData,
                            'formData' : formData,
                            'company' : company
                        }), 
                        headers: new Headers({
                        'Content-Type': 'application/json'
                        })
                    })
                    .then(res=>{
                        if(res.status===201){
                            console.log(res.status)
                            localStorage.removeItem('data')
                            setDisableSave(true)
                            setOpen(true)
                        }else{
                            alert('บันทึกไม่สำเร็จกรุณาลองอีกครั้ง')
                        }
                    })
                    // .then(localStorage.removeItem('data'))
                    // .then(setDisableSave(true))
                    // .then(setOpen(true))
            }
            return 0;
    }
    const options = {
        orientation: 'landscape',
        unit: 'in',
        format: [4,2]
    };
    const scrollTop = () =>{
        window.scrollTo({top: 0, behavior: 'smooth'});
     };
     const scrollBot = () =>{
        //window.scrollTo({bottom: 0, behavior: 'smooth'});
        divRref.current.scrollIntoView({ behavior: 'smooth' });
     };
      const [goTo,setGoto] = React.useState('go to Bottom')
    //   const checkScrollTop = () => {
    //         if (window.pageYOffset > 400){
    //             setGoto('go to Top')
    //         } else if (window.pageYOffset <= 400){
    //             setGoto('go to Bottom')
    //         }
    //   };
    //   window.addEventListener('scroll', checkScrollTop)
    React.useEffect(function setupListener() {
        
        const checkScrollTop = () => {
          if (window.pageYOffset > 400){
              setGoto('go to Top')
          } else if (window.pageYOffset <= 400){
              setGoto('go to Bottom')
          }
      };
      window.addEventListener('scroll', checkScrollTop)
  
      return function cleanupListener() {
        window.removeEventListener('scroll', checkScrollTop)
      }
    })
      const divRref = React.useRef(null);

    function PrintDiv() {
        var divToPrint = document.getElementById('container'); // เลือก div id ที่เราต้องการพิมพ์
	    var html =  '<html>'+ // 
				'<head>'+
				'</head>'+
					'<body onload="window.print();">' + divToPrint.innerHTML + '</body>'+
				'</html>';
				
	var popupWin = window.open();
	popupWin.document.open();
	popupWin.document.write(html); //โหลด print.css ให้ทำงานก่อนสั่งพิมพ์
	popupWin.document.close();
}
    const showEmail=(comp)=>{
        // console.log(props.history.location.state.company)
        fetch(`api/user/findEmailByCompanyName/${comp}`)
        .then(res=>res.json())
        .then(res => {
            if(res.user)
                setEmail(res.user.email)
        })
    }
    React.useEffect(()=>{
        if(company!==''||props.history.location.state.company){
            // console.log(company||props.history.location.state.company)
            var comp = props.history.location.state.company||company
            showEmail(comp)
        }
    },[company])
    
    return (
        <div ref={ref}  style={{padding:'30px',marginBottom:'100px',minWidth:'986px'}}>
            <Button style={{margin:'10px',marginLeft:'0px'}} variant="contained" color="primary" onClick={()=>PrintDiv()}>
                <PictureAsPdfIcon/>
                 <p style={{margin:'0',marginLeft:'10px'}}>Save as PDF / PRINT</p>
            </Button>
            
            {
                (ManualAnswer.length!==0||LabelAnswer.length!==0)?(!disable?((!props.history.location.state._id)?
                <Button style={{margin:'10px'}} variant="contained" color='primary' onClick={handleSaveReport}>บันทึกผลแบบประเมินนี้</Button>:''):''):''
            }
         <Button variant="contained" color="primary" onClick={goTo==='go to Bottom'?scrollBot:scrollTop} style={{ position:'fixed',width:'20px',height:'30px',bottom:'100px',right:'30px'}}>
                 {goTo==='go to Top'?
                     <ArrowUpwardIcon style={{width:'20px',height:'20px'}}/>
                 :
                    <ArrowDownwardIcon style={{width:'20px',height:'20px'}}/>
                 }
             </Button>
            <div style={{display:'flex',minWidth:'986px'}}>
                <div style={{backgroundColor:'#F4F4F4',boxShadow:'0px 4px 4px rgba(0, 0, 0, 0.25)',width:'50%',height:'230px'}}>
                    <h3 style={{backgroundColor:'#F3EFEF', margin:'0px',padding:'20px',boxShadow:'0px 1.5px 1.5px rgba(0, 0, 0, 0.25)'}}>ข้อมูลผลิตภัณฑ์</h3>
                    <div style={{padding:'10px'}}>
                            <table id='tbl1'>
                                <tbody>
                                    <tr id='tbr1'>
                                        <td id='tbd1' align="right" style={{paddingRight:'20px'}}>ชื่อ</td>
                                        <td id='tbd2'>{formData.productName}</td>
                                    </tr>
                                    <tr id='tbr2'>
                                        <td id='tbd3' align="right" style={{paddingRight:'20px'}}>โมเดล</td>
                                        <td id='tbd4'>{formData.model}</td>
                                    </tr>
                                    <tr id='tbr3'>
                                        <td id='tbd5' align="right" style={{paddingRight:'20px'}}>วิธีรับข้อมูล</td>
                                        <td id='tbd6'>{formData.inputType}</td>
                                    </tr>
                                    <tr id='tbr4'>
                                        <td id='tbd7' align="right" style={{paddingRight:'20px'}}>ประเภทผลิตภัณฑ์</td>
                                        <td id='tbd8' >{formData.productType}</td>
                                    </tr>
                                    <tr id='tbr4'>
                                        <td id='tbd7' align="right" style={{paddingRight:'20px'}}>Email ติดต่อ</td>
                                        <td id='tbd8' >{email}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                </div>
                <img src={formData.productImg} alt="" style={{width:'330px' ,position:'absolute',top:'90px',right:'30px'}}></img>
            </div>
            <div style={{display:'flex'}}>
                <p style={{marginRight:'20px'}}>หมายเหตุ</p>
                <div>
                <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                        <DoneIcon fontSize={'large'} style={{marginRight:'25px',color:'#00FF29'}}/>
                        <p>สอดคล้อง</p>
                    </div>
                    <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                        <ClearIcon fontSize={'large'} style={{marginRight:'20px',color:'#FF0000'}}/>
                        <p>ไม่สอดคล้อง</p>
                    </div>
                    <div style={{display:'flex'}}>
                        <p style={{marginRight:'20px'}}>N/A</p>
                        <p>ไม่เกี่ยวข้อง</p>
                    </div>
                </div>
            </div>
            <ToggleButtonGroup
                        value={type}
                        exclusive
                        onChange={handleTypeChange}
                        aria-label="text alignment"
                        size="large"
                        >
                        <ToggleButton value="label" aria-label="left aligned">
                            ฉลาก
                        </ToggleButton>
                        <ToggleButton value="manual" aria-label="centered">
                            คู่มือ
                        </ToggleButton>
                    
                    </ToggleButtonGroup>
                
            <div style={{backgroundColor:'#F3EFEF',padding:'10px',borderRadius:'10px', boxShadow:'0px 4px 4px rgba(0, 0, 0, 0.25)'}}>
                <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="simple table" >
                        <TableHead>
                            <TableRow style={{backgroundColor:'#A7F8F8'}} >
                            <TableCell><h3 style={{margin:'0px'}}>ข้อ</h3></TableCell>
                            <TableCell align="left"><h3 style={{margin:'0px'}}>ข้อกำหนด</h3></TableCell>
                            <TableCell align="left"><h3 style={{margin:'0px'}}>คำตอบ</h3></TableCell>
                            <TableCell align="left"><h3 style={{margin:'0px'}}>ผลการตรวจสอบ</h3></TableCell>
                            <TableCell align="left"><h3 style={{margin:'0px'}}>คำอธิบาย</h3></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody >
                            {
                                type==='manual'?
                                ManualAnswer?
                                ManualAnswer.map((data,index)=>{
                                    return (
                                        <TableRow key={`r-${index}`} onClick={()=>{}} style={{backgroundColor:(data.questionSchema.ans==="true"||data.questionSchema.ans===true)?'#E3F9DA':'#F8D3D3'}}  className={classes.tableRow} >
                                            <TableCell style={{paddingLeft:(data.questionSchema.head.toString().replaceAll('.','').length===1)?'10px':`${data.questionSchema.head.toString().replaceAll('.','').length*8}px`,
                                            }} component="th" scope="row">{data.questionSchema.head}</TableCell>
                                            <TableCell align="left">{data.questionSchema.question}</TableCell>
                                            <TableCell align="left">{data.questionSchema.txtInput}</TableCell>
                                            <TableCell align="left">{
                                                (data.questionSchema.isUse===false)?
                                                   "N/A":
                                                    (data.questionSchema.ans==="true"||data.questionSchema.ans===true)?
                                                        <DoneIcon style={{color:'#00FF29'}}/>:
                                                        <ClearIcon style={{color:'#FF0000'}}/>
 
                                                }
                                            </TableCell>
                                            <TableCell align="left">{data.questionSchema.desc}</TableCell>
                                        </TableRow>
                                    )
                                }):''
                                :
                                LabelAnswer?
                                LabelAnswer.map((data,index)=>{
                                    return (
                                        <TableRow key={`r-${index}`} onClick={()=>{}} style={{
                                            backgroundColor:
                                            (data.questionSchema.isUse===false)?
                                            '#FDED62':
                                            (data.questionSchema.ans==="true"||data.questionSchema.ans===true)
                                            ?
                                                '#E3F9DA'
                                            :
                                                '#F8D3D3'
                                        }}  className={classes.tableRow} >
                                            <TableCell style={{paddingLeft:(data.questionSchema.head.toString().replaceAll('.','').length===1)?'10px':`${data.questionSchema.head.toString().replaceAll('.','').length*8}px`,
                                            }}
                                             component="th" scope="row">{data.questionSchema.head}</TableCell>
                                            <TableCell align="left">{data.questionSchema.question}</TableCell>
                                            <TableCell align="left">{data.questionSchema.txtInput}</TableCell>
                                            <TableCell align="left">{
                                                        (data.questionSchema.isUse===false)?
                                                        <h3 style={{margin:'0px'}}>N/A</h3>:
                                                        (data.questionSchema.ans==="true"||data.questionSchema.ans===true)?
                                                            <DoneIcon style={{color:'#00FF29'}}/>:
                                                            <ClearIcon style={{color:'#FF0000'}}/>
                                                        }
                                            </TableCell>
                                            <TableCell align="left">{data.questionSchema.desc}</TableCell>
                                        </TableRow>
                                    )
                                }):''
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            <Snackbar 
                open={open}
                autoHideDuration={1000}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                style={{marginTop:'100px'}}
                >
                <Alert onClose={handleClose} severity="success">
                    Save Success
                </Alert>
                </Snackbar>
            <div ref={divRref}></div>

             <div id="container" style={{display:'none'}}>
             <div style={{display:'flex',minWidth:'986px'}}>
                <div style={{backgroundColor:'#F4F4F4',boxShadow:'0px 4px 4px rgba(0, 0, 0, 0.25)',width:'50%',height:'200px'}}>
                    <h3 style={{backgroundColor:'#F3EFEF', margin:'0px',padding:'20px',boxShadow:'0px 1.5px 1.5px rgba(0, 0, 0, 0.25)'}}>ข้อมูลผลิตภัณฑ์</h3>
                    <div style={{padding:'10px'}}>
                            <table id='tbl1'>
                                <tbody>
                                    <tr id='tbr1'>
                                        <td id='tbd1' align="right" style={{paddingRight:'20px'}}>ชื่อ</td>
                                        <td id='tbd2'>{formData.productName}</td>
                                    </tr>
                                    <tr id='tbr2'>
                                        <td id='tbd3' align="right" style={{paddingRight:'20px'}}>โมเดล</td>
                                        <td id='tbd4'>{formData.model}</td>
                                    </tr>
                                    <tr id='tbr3'>
                                        <td id='tbd5' align="right" style={{paddingRight:'20px'}}>วิธีรับข้อมูล</td>
                                        <td id='tbd6'>{formData.inputType}</td>
                                    </tr>
                                    <tr id='tbr4'>
                                        <td id='tbd7' align="right" style={{paddingRight:'20px'}}>ประเภทผลิตภัณฑ์</td>
                                        <td id='tbd8' >{formData.productType}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                </div>
                <img src={formData.productImg} alt="" style={{width:'200px' ,position:'absolute',top:'0px',right:'30px'}}></img>
            </div>
                 <h1>ฉลาก</h1>
                <table>
                <tr>
                    <th>ข้อ</th>
                    <th>ข้อกำหนด</th>
                    <th>ผลการตรวจสอบ</th>
                </tr>
                    {
                        LabelAnswer.map((data,index)=>{
                            return (
                                <tr key={`r-${index}`} onClick={()=>{}} style={{
                                    backgroundColor:
                                    (data.questionSchema.isUse===false)?
                                    '#FDED62':
                                    (data.questionSchema.ans==="true"||data.questionSchema.ans===true)
                                    ?
                                        '#E3F9DA'
                                    :
                                        '#F8D3D3'
                                }}  className={classes.tableRow} >
                                    <td style={{paddingLeft:(data.questionSchema.head.toString().replaceAll('.','').length===1)?'10px':`${data.questionSchema.head.toString().replaceAll('.','').length*8}px`,
                                    }}
                                    component="th" scope="row">{data.questionSchema.head}</td>
                                    <td align="left">{data.questionSchema.question}</td>
                                    <td align="left">{
                                                (data.questionSchema.isUse===false)?
                                                <h5 style={{margin:'0px'}}>N/A</h5>:
                                                (data.questionSchema.ans==="true"||data.questionSchema.ans===true)?
                                                    <h5 style={{margin:'0px'}}>C</h5>:
                                                    <h5 style={{margin:'0px'}}>NC</h5>
                                                }
                                    </td>
                                   
                                </tr>
                            )
                        })
                    }
                </table>

                <h1>คู่มือ</h1>
                <table>
                <tr>
                    <th>ข้อ</th>
                    <th>ข้อกำหนด</th>
                    <th>ผลการตรวจสอบ</th>
                </tr>
                    {
                        ManualAnswer.map((data,index)=>{
                            return (
                                <tr key={`r-${index}`} onClick={()=>{}} style={{
                                    backgroundColor:
                                    (data.questionSchema.isUse===false)?
                                    '#FDED62':
                                    (data.questionSchema.ans==="true"||data.questionSchema.ans===true)
                                    ?
                                        '#E3F9DA'
                                    :
                                        '#F8D3D3'
                                }}  className={classes.tableRow} >
                                    <td style={{paddingLeft:(data.questionSchema.head.toString().replaceAll('.','').length===1)?'10px':`${data.questionSchema.head.toString().replaceAll('.','').length*8}px`,
                                    }}
                                    component="th" scope="row">{data.questionSchema.head}</td>
                                    <td align="left">{data.questionSchema.question}</td>
                                   
                                    <td align="left">{
                                                (data.questionSchema.isUse===false)?
                                                <h5 style={{margin:'0px'}}>N/A</h5>:
                                                (data.questionSchema.ans==="true"||data.questionSchema.ans===true)?
                                                    <h5 style={{margin:'0px'}}>C</h5>:
                                                    <h5 style={{margin:'0px'}}>NC</h5>
                                                }
                                    </td>
                                </tr>
                            )
                        })
                    }
                </table>
            </div> 
        </div>
    );
}

export default Report;
