import React, { useContext } from 'react';
import Context from './context';
import login from './page/loginPage'
import {Router, Route,Redirect} from "react-router";
import './App.css';
import AuthCheck from './utils/authcheck';
import history from './utils/history';
import Navbar from './nav/Navbar';
import register from './page/registerPage';
import Activate from './page/activateAccount'
import forgetpass from './page/forgetPassPage';
import ResetPass from './page/resetPass';
import Footer from './component/footer';
import report from './page/report';
import labelPage from './page/labelPage';
import addQuestion from './page/addQuestion'
import Evaluate from './page/evaluate';
import questionList from './page/listQuestionGroupPage'
import treeStruct from './page/TreeStructPage'
import Profile from './page/profile'
import assesment from './page/assesment'
const  Routes =() =>{

  const Standardinfo = () => { return (<div><h2>ข้อมูลมาตรฐาน</h2></div>); }
  

  const context = useContext(Context)
  const [role,setRole] = React.useState(null)
  React.useEffect(() => {
    if (context.authObj && context.authObj.isAuthenticated() && !context.profileState) {
      context.authObj.getProfile()
//      .then(profile => profile)
      .then(profile => {
     
        if (profile) context.handleUserResetProfile({is_authenticated: true, profile: profile})
      })
    }
    // console.log(context.authObj.isAuthenticated())
    // console.log(checkAdmin())
  })
  const checkAdmin = () =>{
    if(context.authObj.isAuthenticated()){
      if(context.profileState){
        {
          if(context.profileState.role==='admin')
            return true
        }
      }
    }
    return false
  }

    return (
        <div>
          <Router history={history}>
            <Navbar/>
            <Route exact path="/" component={login} />
            <Route exact path="/evaluate" component={context.authObj.isAuthenticated()?Evaluate:login} />
            <Route exact path="/standardinfo" component={context.authObj.isAuthenticated()?Standardinfo:login} />
            <Route exact path="/activate/:_id" children={<Activate/>} />
            <Route exact path="/resetpassword/:_id" component={ResetPass} />
            <Route path='/authcheck' component={AuthCheck} />
            <Route path="/report" component={context.authObj.isAuthenticated()?report:login} />
            <Route path="/login" component={context.authObj.isAuthenticated()?Evaluate:login} />
            <Route path="/register" component={register} />
            <Route path="/forgetpass" component={forgetpass} />
            <Route path="/labelPage" component={context.authObj.isAuthenticated()?labelPage:login}/>
            <Route path="/addQuestion" component={(checkAdmin())?addQuestion:(context.authObj.isAuthenticated()?Evaluate:login)}/>
            <Route path="/listQuestion" component={(checkAdmin())?questionList:(context.authObj.isAuthenticated()?Evaluate:login)}/>
            <Route path="/treeStruct" component={(checkAdmin())?treeStruct:(context.authObj.isAuthenticated()?Evaluate:login)}/>
            <Route path="/Profile" component={context.authObj.isAuthenticated()?Profile:login}/>
            <Route path="/assesment" component={context.authObj.isAuthenticated()?assesment:login}/>
          </Router>
          <Footer/>
        </div>
    );
}
export default Routes;