import React, {useEffect} from 'react';
import { useState } from 'react';
import Button from '@material-ui/core/Button'
import { Typography } from '@material-ui/core';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Grid from '@material-ui/core/Grid';


//page แสดงรายการคำถาม ของ ฉลากและคู่มือ
function LabelPage(props) {
    const [type, setType] = useState('label');
    var data = []
    var data2 = []
    const [treedata2,setTreedata2] = useState({ treeData: []})
    const [treedata,setTreedata] = useState({treeData: []})
    const handleTypeChange = (event, newType) => {
        console.log(newType)
        setType(newType);
       /* if(newType===null){
            document.getElementById("label").style.color = "rgba(0, 0, 0, 0.38)"
            document.getElementById('manual').style.color = "rgba(0, 0, 0, 0.38)"
        }
        if(newType!==null){
            var color = document.getElementById(newType).style.color
            console.log(color)
            if(newType==='label'){
                document.getElementById(newType).style.color = "black"
                document.getElementById('manual').style.color = "rgba(0, 0, 0, 0.38)"
            }
            if(newType==='manual'){
                document.getElementById(newType).style.color = "black"
                document.getElementById('label').style.color = "rgba(0, 0, 0, 0.38)"
            }
        }*/
      };
    const fetchDataLabel = async () => {
            await fetch('/LabelquestionGroupList')
            .then(res=>res.json())
            .then(j=>{
                j.map(r=>{
                    // console.log(r)
                    data.push(r)
                    return 0
                })
            })
            setTreedata({ 
                treeData : data
              })
            // console.log(data.length)
            return 0
          };
    //console.log(treedata)

    const fetchDataManual = async () => {
        await fetch('/ManualquestionGroupList')
        .then(res=>res.json())
        .then(j=>{
            j.map(r=>{
                // console.log(r)
                data2.push(r)
                return 0
            })
        })
        setTreedata2({ 
            treeData : data2
          })
        // console.log(data2.length)
          return 0
      };

    useEffect(() => {
          fetchDataLabel();
          fetchDataManual();
    }, [])
    // useEffect(() => {
    //     console.log(treedata2.treeData.length)
    //     console.log(treedata.treeData.length)
    // }, )
    // console.log(data)
    // setTreedata(data.treeData)
    const newgroup = { 
        title: 'Example question',
        questionSchema:{
        head:'',
        question : "question",
        desc:"Description",
        isCheck:false,
        isUse:true,
        C:false,
        NC:false,
        NA:false,
        ans:true,
        isAns:false,
        txtInput:'',
        keyWord:''
    }}
    const [act,setAct] = useState(true)
    const handleDelete=(id)=>{
        if(type==='label'){
            // setTreedata(treedata.treeData.filter(item => item._id !== id))
            console.log(treedata)
            fetch('/deleteLabelQuestionGroup', {
                method: 'DELETE',
                body: JSON.stringify({'id':id}),
                headers: new Headers({
                'Content-Type': 'application/json'
                })
            })
            .then(res=>res.json())
            .then(r => console.log(r))
            .then(fetchDataLabel())
            

            
        }else{
            fetch('/deleteManualQuestionGroup', {
                method: 'DELETE',
                body: JSON.stringify({'id':id}),
                headers: new Headers({
                'Content-Type': 'application/json'
                })
            })
            .then(res=>res.json())
            .then(r => console.log(r))
            .then(fetchDataManual())
            // .then(()=>{
            //     if(treedata2)
            //         setTreedata2(treedata2.filter(item => item._id !== id))
            //})
        }
    }

    
    var List = () =>{
        // console.log(treedata.treeData)
            return (
                type==='label'?(
                    (treedata.treeData!==undefined)?(treedata.treeData.map((res,i)=>{
                        // data.map((res,i)=>{
                        return (
                                    <div key={i} onClick={()=>{
                                        //props.history.push('/addQuestion', { id: 1, data:res})
                                    }} style={{padding:20,margin:20,backgroundColor:"#E5E5E5",boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",borderRadius:12}}>
                                        <h4>Group {i+1}</h4>
                                        {JSON.stringify(res.question[0].questionSchema.question)}
                                        <Button style={{marginLeft:20}}
                                            onClick={()=>{
                                                props.history.push('/addQuestion', { id: res._id, data:res.question[0],type:type ,group:i+1})
                                            }}>
                                            <Typography style={{color:"#CA5A45"}}>แก้ไข</Typography>
                                        </Button>
                                        <Button style={{marginLeft:20}}
                                            onClick={()=>{
                                                props.history.push('/treeStruct', { id: 1, data:res.question[0],group:i+1})
                                            }}>
                                            <Typography style={{color:"#CA5A45"}}>ดูโครงสร้างคำถามแบบต้นไม้</Typography>
                                        </Button> 
                                        <Button style={{marginLeft:20}}
                                            onClick={()=>{
                                                handleDelete(res._id)
                                            }}>
                                            <Typography style={{color:"#CA5A45"}}>ลบ</Typography>
                                        </Button>
                                    </div>
                                )
                    })):''
                ):(type==='manual')?(
                    treedata2.treeData.map((res,i)=>{
                        return (
                                    <div key={i} onClick={()=>{
                                        //props.history.push('/addQuestion', { id: 1, data:res})
                                    }} style={{padding:20,margin:20,backgroundColor:"#E5E5E5",boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",borderRadius:12}}>
                                        <h4>Group {i+1}</h4>
                                        {JSON.stringify(res.question[0].questionSchema.question)}
                                        <Button style={{marginLeft:20}}
                                            onClick={()=>{
                                                props.history.push('/addQuestion', { id: res._id, data:res.question[0],type:type ,group:i+1})
                                            }}>
                                            <Typography style={{color:"#CA5A45"}}>แก้ไข</Typography>
                                        </Button>
                                        <Button style={{marginLeft:20}}
                                            onClick={()=>{
                                                props.history.push('/treeStruct', { id: 1, data:res.question[0],group:i+1})
                                            }}>
                                            <Typography style={{color:"#CA5A45"}}>ดูโครงสร้างคำถามแบบต้นไม้</Typography>
                                        </Button> 
                                        <Button style={{marginLeft:20}}
                                            onClick={()=>{
                                                handleDelete(res._id)
                                            }}>
                                            <Typography style={{color:"#CA5A45"}}>ลบ</Typography>
                                        </Button>
                                    </div>
                                )
                    })
                ):""
            

        )
    }
    return (
        <div style={{padding:20,marginBottom:'200px'}}>
            <div style={{margin:20,display:'flex'}}>
                
                <Grid container spacing={3} style={{marginLeft:'10px'}} direction="row" alignItems="center">
                <h3>ประเภทคำถาม</h3>
                <Grid item>
                    <ToggleButtonGroup
                        // style={{backgroundColor:'orange'}}
                        style={{boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.25)"}}
                        value={type}
                        exclusive
                        onChange={handleTypeChange}
                        aria-label="text alignment"
                        size="large"
                        >
                        <ToggleButton id="label" style={{width:'100px'}} value="label" aria-label="left aligned">
                            <h4 style={{margin:'0'}}>ฉลาก</h4>
                        </ToggleButton>
                        <ToggleButton id="manual" style={{width:'100px'}} value="manual" aria-label="centered">
                            <h4 style={{margin:'0'}}>คู่มือ</h4>
                        </ToggleButton>
                    
                    </ToggleButtonGroup>
                    </Grid>
                </Grid>
                <Button variant="outlined" style={{height:60,width:200,margin:20}} onClick={()=>{
                    console.log(treedata.treeData2)
                            if(type==='label')
                                props.history.push('/addQuestion', { id: 1,data:newgroup,type:type,group:treedata.treeData.length+1})
                            if(type === 'manual')
                                props.history.push('/addQuestion', { id: 1,data:newgroup,type:type,group:treedata2.treeData.length+1})
                            }}>สร้าง Group เพิ่ม</Button>
            </div>
            {List()}
        </div>
    );
}

export default LabelPage;
 /*{ title: 'ระบบต้องแสดงเครื่องหมาย หรือข้อความบนเปลือกหุ้มด้านนอกของบรรจุภัณฑ์ และบนเปลือกหุ้มของบริภัณฑ์หรือระบบ (ฉลาก)  เห็นได้ง่ายและชัดเจน หรือไม่',questionSchema:{
            question : "",desc:"ฟหกดแฟหกดฟ",isCheck:false,C:false,NC:false, NA:false
        }},
        {title: 'ระบบต้องแสดงเครื่องหมาย หรือข้อความบนเปลือกหุ้มด้านนอกของบรรจุภัณฑ์ และบนเปลือกหุ้มของบริภัณฑ์หรือระบบ (ฉลาก)  มีข้อมูลผลิตภัณฑ์ หรือไม่',questionSchema : {
                        question : "",isCheck:false,desc:"ฟหกดแฟหกดฟ",C:false,NC:false,NA:false
                    },children:[
                        {title:'กรุณาระบุ  ข้อมูลผลิตภัณฑ์ - ชื่อแบบรุ่น  ที่อยู่บน ฉลาก',questionSchema :{
                        question : "ฟหกดแฟหกดฟ",desc:"",isCheck:false,C:false,NC:false,NA:false
                        }},{title:'กรุณาระบุ  ข้อมูลผลิตภัณฑ์ - ชื่อผู้จัดทำ (ผู้ผลิต)  ที่อยู่บน ฉลาก',questionSchema:{
                            question : "ฟหกดแฟหกดฟ",desc:"",isCheck:false,C:false,NC:false,NA:false
                        }},{title:'กรุณาระบุ  ข้อมูลผลิตภัณฑ์ - ประเภทของข้อมูลจราจรทางคอมพิวเตอร์ที่จัดเก็บได้  ที่อยู่บน ฉลาก',questionSchema:{
                            question : "ฟหกดแฟหกดฟ",desc:"",isCheck:false,C:false,NC:false,NA:false
                        }},{title:'กรุณาระบุ ข้อมูลผลิตภัณฑ์ -  Processor ที่อยู่บน ฉลาก',questionSchema:{
                            question : "ฟหกดแฟหกดฟ",desc:"",isCheck:false,C:false,NC:false,NA:false
                        },children:[
                            {title:'กรุณาระบุ ข้อมูลผลิตภัณฑ์ - ชื่อยี่ห้อ CPU ที่อยู่บน ฉลาก',questionSchema:{
                            question : "ฟหกดแฟหกดฟ",desc:"",isCheck:false,C:false,NC:false,NA:false
                        }},{title:'กรุณาระบุ ข้อมูลผลิตภัณฑ์ - รุ่น CPU ที่อยู่บน ฉลาก',questionSchema:{
                            question : "ฟหกดแฟหกดฟ",desc:"",isCheck:false,C:false,NC:false,NA:false
                        }},{title:'กรุณาระบุ ข้อมูลผลิตภัณฑ์ - ความเร็ว CPU (Ghz) ที่อยู่บน ฉลาก',questionSchema:{
                            question : "ฟหกดแฟหกดฟ",desc:"",isCheck:false,C:false,NC:false,NA:false
                        }}
                        ]},{title:'กรุณาระบุ ข้อมูลผลิตภัณฑ์ - RAM หรือไม่',questionSchema:{
                            question : "ฟหกดแฟหกดฟ",desc:"",isCheck:false,C:false,NC:false,NA:false
                        },children:[{title:'กรุณาระบุ ข้อมูลผลิตภัณฑ์ - ชื่อยี่ห้อ RAM  อะไร',questionSchema:{
                            question : "ฟหกดแฟหกดฟ",desc:"",isCheck:false,C:false,NC:false,NA:false
                        }},{title:'กรุณาระบุ  ข้อมูลผลิตภัณฑ์ - รุ่น RAM อะไร',questionSchema:{
                            question : "ฟหกดแฟหกดฟ",desc:"",isCheck:false,C:false,NC:false,NA:false
                        }},{title:'กรุณาระบุ  ข้อมูลผลิตภัณฑ์ - ขนาด RAM เท่าไร',questionSchema:{
                            question : "ฟหกดแฟหกดฟ",desc:"",isCheck:false,C:false,NC:false,NA:false
                        }},{title:'กรุณาระบุ  ข้อมูลผลิตภัณฑ์ - Hard disk อะไร',questionSchema:{
                            question : "ฟหกดแฟหกดฟ",desc:"",isCheck:false,C:false,NC:false,NA:false
                        }}
                    ]}
                    ]}*/

                    

// {treedata.treeData.map((res,i)=>{
//     return (
//         <div key={i} onClick={()=>{
//              //props.history.push('/addQuestion', { id: 1, data:res})
//         }} style={{padding:20,margin:20,backgroundColor:"#E5E5E5",boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",borderRadius:12}}>
//             <h4>Group {i+1}</h4>
//             {JSON.stringify(res.question[0].title)}
//              <Button style={{marginLeft:20}}
//                  onClick={()=>{
//                      props.history.push('/treeStruct', { id: 1, data:res.question[0]})
//                  }}>
//                  <Typography style={{color:"#CA5A45"}}>ดูโครงสร้างคำถามแบบต้นไม้</Typography>
//              </Button> 

//              <Button style={{marginLeft:20}}
//                  onClick={()=>{
//                      props.history.push('/addQuestion', { id: res._id, data:res.question[0]})
//                  }}>
//                  <Typography style={{color:"#CA5A45"}}>แก้ไข</Typography>
//              </Button>

//              <Button style={{marginLeft:20}}
//                  onClick={()=>{
//                      handleDelete(res._id)
//                  }}>
//                  <Typography style={{color:"#CA5A45"}}>ลบ</Typography>
//              </Button>

//         </div>
//     )
// })}