import React from 'react';
import Tree from 'react-d3-tree';

//page แสดงโครงสร้างคำถาม
export default function TreeStructPage(props) {
    //console.log(props.history.location.state.data)
    const data = JSON.stringify(props.history.location.state.data)
    const data2 = data.replaceAll("title","quest")
    var data3 = JSON.parse(data2)
    // data3.att={k:'val'}
    // console.log(data3)
    var data4 = [data3]
    const containerStyles = {
        width: '100%',
        height: '580px',
        border:'1px solid #777',
        borderRadius:'10px'
      }
    const traverse = (obj) => {
      // console.log(obj)
        for(let i = 0;i<obj.length;i++){
            var tmp = obj[i]
            if(obj[i].quest.length > 10){
              obj[i].attributes={คำถาม:obj[i].quest.substring(0,20)+'...'}
            }else{
              obj[i].attributes={คำถาม:obj[i].quest}
            }
            obj[i].name= obj[i].questionSchema.head
            if(!obj[i].questionSchema.isCheck){
              obj[i].nodeSvgShape = {
                shape: 'circle',
                shapeProps: {
                  r: 10,
                  
                }
                }
            }
            else
              obj[i].nodeSvgShape = {
                shape: 'rect',
                shapeProps: {
                  width: 20,
                  height: 20,
                  x: -10,
                  y: -10,
                  
                }
              }
            // console.log(obj[i])
            if(tmp.children){
                traverse(tmp.children)
            }
        }
   }
   traverse(data4)
  //  console.log(data4)



    const translate =  {
        x:  window.innerWidth / 2,
        y:  window.innerHeight / 2
      }
    const defaultTranslate = { x: 0, y: 0 }
    //const data4 = data3.push({_collapsed:false})
    const [translate1, setTranslate1] = React.useState(defaultTranslate);
    const containerRef = React.useCallback((containerElem) => {
      if (containerElem !== null) {
        const { width, height } = containerElem.getBoundingClientRect();
        setTranslate1({ x: width / 2, y: height / 2 });
      }
    }, []);
   
    const [nodeDatainfo,setNodeData] = React.useState()
    React.useEffect(() => {
      // do your magic here
      console.log("x")
    }, [nodeDatainfo]);
    const txtStyle = {
        whitespace: 'nowrap',
        overflow: 'hidden',
        textoverflow: 'ellipsis',
    }
    class NodeLabel extends React.PureComponent {
      render() {
        const {nodeData} = this.props
        var question = ''
        if(nodeData.quest.length>75){
          question = nodeData.quest.substring(0,75)+"..."
        }
        else{
          question = nodeData.quest
        }
        return (
          <div style={{width:'200px'}}>
            <div>{nodeData.name}</div>
            <div >{
              question
            }</div>
            
          </div>
        )
      }
    }
    
    return (
        <div style={{padding:"30px"}}>
          <h2>{"แผนภาพโครงสร้างคำถาม Group ที่  "+props.history.location.state.group}</h2>
            <div id="treeWrapper" style={containerStyles} ref={containerRef}  >
              <Tree data={data4}
                    allowForeignObjects
                    nodeLabelComponent={{
                      render: <NodeLabel />,
                      foreignObjectWrapper: {
                        x:10,
                        y: -20
                      }
                    }}
                    
                    orientation="vertical"
                    pathFunc="step"
                    translate={translate1}
                    nodeSize={{x:220,y:150}}
                    // textLayout={{textAnchor:"start", x:-0, y: 25, transform:"rotate(10 50 10)" }}
                />
            </div>
                <div style={{marginTop:'10px', display:'flex',minWidth:'1055px'}}>
                  <div style={{display:'flex',padding:'10px',alignItems:'center'}}>
                    <div style={{border:'3px solid black',width:'50px',height:'50px',backgroundColor:'#777',borderRadius:'50%',display:'inline-block'}}></div>
                    <div style={{margin:'10px',fontSize:'18px'}}> = AND node</div>
                  </div>

                  <div style={{display:'flex',padding:'10px',alignItems:'center'}}>
                   <div style={{border:'3px solid black',width:'50px',height:'50px',backgroundColor:'#777',display:'inline-block'}}></div>
                   <div style={{margin:'10px',fontSize:'18px'}}> = OR node</div>
                  </div> 
                  
                  <div style={{padding:'10px'}}>
                    <div style={{display:'flex',margin:'0',alignItems:'center'}}>
                      <div style={{border:'2px solid black',width:'10px',height:'10px',backgroundColor:'#777',borderRadius:'50%',display:'inline-block'}}></div>
                    <div style={{fontSize:'18px'}}>(Parent node) หมายถึง โหนดที่มีโหนดย่อย เมื่อกดแล้วสามารถขยายหรือยุบโหนดย่อยได้</div>
                    </div>

                    <div style={{display:'flex',margin:'0',alignItems:'center'}}>
                      <div style={{border:'2px solid black',width:'10px',height:'10px',borderRadius:'50%',display:'inline-block'}}></div>
                      <div style={{fontSize:'18px'}}>(Leaf node) หมายถึง โหนดที่ไม่มีโหนดย่อย เมื่อกดแล้วไม่สามารถขยายหรือยุบโหนดย่อยได้</div>
                    </div>
                  </div> 

                </div>
                
        </div>
    )
}
