import { Button } from '@material-ui/core';
import React,{useState} from 'react';
import { useParams } from 'react-router-dom';


//page หน้า activeaccount
function ActivateAccount() {

    const [isActive,setIsActive] = useState(false)
    const [err,serErr] = useState('')
    const {_id} = useParams()
    const handleClick = () =>{
        fetch(`/api/user/activate/${_id}`, {
            method: 'PUT',
            headers: new Headers({
            'Content-Type': 'application/json'
            })
            }).then(res=>res.status)
            .then(
                res=>{
                    console.log(res)
                    if(res!==404)
                        setIsActive(true)
                    else
                        serErr('ข้อมูลผิดพลาด')
                }
                
                )
            
            
    }
    
    return (
        <div  style={{display:"flex",height:'100%',marginTop:'2%',justifyContent:'center',alignItems:"center"}}>
            <form style={{flexDirection:'column'}} >
            <h1 style={{alignItems:"center",marginLeft:'5%',color:'red'}} >{err}</h1>
                {
                    (!isActive)?(
                        <div style={{alignItems:"center",marginLeft:'5%'}}>
                      
                            <Button style={{display:'block'}} onClick={handleClick}
                         
                            variant="contained"
                            color="primary"
                            
                            >{"Click to Activate Account"}
                            </Button>
                        </div>
                    )
                    :
                    (
                        <div style={{alignItems:"center",marginLeft:'5%'}}>
                            <h2>บัญชีของท่านสามารถเข้าใช้งานระบบประเมินผลิตภัณฑ์ ตามมาตรฐานระบบเก็บรักษาข้อมูลจราจรทางคอมพิวเตอร์ ได้เป็นที่เรียบร้อย</h2>
                        </div>
                    )
                }
            </form>
        </div>
    );
}

export default ActivateAccount;