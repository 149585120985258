import React,{ useState,useContext,} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Context from '../context';
import * as loadingData from "../loading.json";
import FadeIn from "react-fade-in";
import Lottie from "react-lottie";
import ReCAPTCHA from "react-google-recaptcha";
import config from '../config.json'
import { useHistory } from 'react-router';

//style
const useStyles = makeStyles((theme) => ({
  root: {
        marginTop: theme.spacing(3),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        fontFamily:[
          'Kanit', 'sans-serif'
        ],
        
  },
  form:{
    width: '100%',
    marginTop: theme.spacing(1),
    fontFamily:[
      'Kanit', 'sans-serif'
    ]
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    fontFamily:[
      'Kanit', 'sans-serif'
    ]
 
  },
}));
const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: loadingData.default,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};
const defaultOptions2 = {
  loop: true,
  autoplay: true,
  animationData: loadingData.default,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};

//page login
export default function LoginPage() {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const logo = require('../Logo/Logo_SAS-LOG-01.png')
  const [email,setEmail] = useState('');
  const [password,setPass] = useState('');
  const [errormsg,setMsg] = useState('');
  const context = useContext(Context);
  const reCapcha = React.useRef()
  const [Token,setToken] = useState('')
  function onChange(value) {
    console.log("Captcha value:", value);
  }
  const [err,setErr] = React.useState('')

  const [click,setClick] = React.useState(true)
  async function  handleSubmit(event){
      event.preventDefault();
      setErr('')
      if(!Token||Token===''){
        setMsg("คุณยังไม่ได้กด check i'm not robot")
        return
      }
     await context.authObj.signin({email,password},setErr)
    //  console.log(context.err)
      setClick(!click)
      setTimeout(() => {
          // setMsg(localStorage.getItem('errorlogin'))
          // localStorage.removeItem('errorlogin')
          setToken("")
          reCapcha.current.reset()
      }, 300);
    setClick(!click)
  }
  handleSubmit.bind(this)
 
  function handleChangeEmail(event){
    setEmail(event.target.value)
  }
  function handleChangePass(event){
    setPass(event.target.value)
  }
  const history = useHistory()

  React.useEffect(()=>{
    // console.log(err)
    // console.log(Token)
  })

  const values = ''
  return (
    <div className={classes.root} style={{height:'100%',marginBottom:'100px'}}>
      
    <form 
      onSubmit={
        handleSubmit
      }
      className={classes.root} noValidate autoComplete="off">
      <img src={logo} style={{width:'400px'}} alt='logo'/>
      <div style={{width:'300px',marginTop:'-50px'}}>
    <TextField onChange={handleChangeEmail}
            style={{fontFamily:[
              'Kanit', 'sans-serif'
            ]}}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            value={values.email}
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
          />
         
           <TextField onChange={handleChangePass}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            value={values.password}
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />
             <p style={{color:'red',margin:'0'}}>{err.globalError}</p>
             <p style={{color:'red',margin:'0'}}>{errormsg}</p>
          <ReCAPTCHA
              ref={reCapcha}
              sitekey={config.GOOGLE.webKeyRecapcha}
              onChange={token=>{setToken(token)
                                setMsg('')}}
              onExpired={e=>setToken("")}
          />

           <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            style={{borderRadius:'20px',padding:'10px'}}
          >
            เข้าสู่ระบบ
          </Button>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Link style={{fontFamily:[
              'Kanit', 'sans-serif'
            ],cursor:'pointer'}} onClick={()=>{
              history.push('/forgetpass')
            }} variant="body2">
                ลืมรหัสผ่าน?
              </Link>
           
            </Grid>
            <Grid item>
              <Link style={{fontFamily:[
              'Kanit', 'sans-serif'
            ],cursor:'pointer'}} onClick={()=>{
              history.push('/register')
            }} variant="body2">
                ลงทะเบียนสมาชิกใหม่
              </Link>
              
            </Grid>
          </Grid>
          </div>
      </form>
      <FadeIn>
            <div style={{ display: "flex" }}>
              {loading ? (
                <div>
                  <Lottie options={defaultOptions} height={140} width={140}/>
                </div>
              ) : (
                ''
              )}
            </div>
      </FadeIn>
   
    </div>
  );
}