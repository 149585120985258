
import React from 'react';

function footer(props) {
    //background-color: #EC5231;color: #fce4ec; position: fixed;bottom: 0;width: 100%;display: flex;justify-content: flex-end; padding-right: 100px;
    const logo = require('../component/Logo_NECTEC-2020_white.png')
    return (
        <footer style={{backgroundColor:"#EC5231",
        color:"#fce4ec",position:"fixed",bottom:"0",
        width: "100%",display:"flex",justifyContent:"flex-end",
        zIndex:999
       }}>
            <div style={{marginRight:"auto",paddingLeft:'10px',display:'flex',alignItems:'center'}}>

                <h5>ระบบประเมินผลิตภัณฑ์ ตามมาตรฐานระบบเก็บรักษาข้อมูลจราจรทางคอมพิวเตอร์</h5>
            </div>
            <div> 
                <img src={logo} style={{width:'10em',marginTop:'10px'}} alt="necteclogo"/>
            </div>
            <div style={{padding:'0px'}} >
            <p style={{fontSize:'13px',marginTop:'13px',paddingRight:'13px'}}>Copyright @2020 <br/>
                งานวิศวกรรมซอฟแวร์และทดสอบผลิตภัณฑ์(SEPT)<br/>
                ศูนย์เทคโนโลยีอิเล็คทรอนิกส์และคอมพิวเตอร์แห่งชาติ (NECTEC)
            </p>
            </div>
        </footer>
      
    );
}

export default footer;