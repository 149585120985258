import React,{useContext} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Context from '../context';
import Button from '@material-ui/core/Button';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import * as loadingData from "../loading.json";
import FadeIn from "react-fade-in";
import Lottie from "react-lottie";
import TextField from '@material-ui/core/TextField';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: loadingData.default,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  tableRow: {
    '&:hover': {
      background: '#F1A897 !important',
    },
  },
  remove :{
    '&:hover':{
      background: '#F61F2C ',
      boxShadow:'0px 3px 3px rgba(0, 0, 0, 0.25)'
    }
  },
  
});


//data schema
function createData(productName, model, createDate, company, result,_id,userLabelAnswer,userManualAnswer,treeLabel,treeManual,formData) {
  return { productName, model, createDate, company, result ,_id,userLabelAnswer,userManualAnswer,treeLabel,treeManual,formData};
}

//page แสดงรายการ รายงานผลแบบปรระเมินตนเอง
export default function Evaluate(props) {

  const context = useContext(Context);

  const [profile,setProfile] = React.useState(context)
  const [role,setRole] = React.useState()

  const [rows,setRows] = React.useState([])
  const classes = useStyles();
  const [search,setSearch] = React.useState('');

  const [filterdata,setFilterdata] = React.useState([])
  const [reportData,setReportData] = React.useState([])
  const [id,setId] = React.useState('')
  React.useEffect(()=>{
    setProfile(context.profileState)
   /* const row = []
    data.map(x=>{
      row.push(createData(x.product_name,x.Model,x.createDate,x.company,x.result))
    })
    setRows(row)*/

    if(role==='admin'){
      console.log('admin here boiz')
    }
    if (context.authObj) {
      context.authObj.getProfile()
      .then(profile => {
        // console.log(profile)
        setId(profile._id)
        setRole(profile.role)
      })
    }

  },[])
  
  const handleRowClick=(data)=>{
  
    //console.log(data)
    props.history.push('/report',{
      labelAnswer:data.userLabelAnswer,
      manualAnswer:data.userManualAnswer,
      labelTree:data.treeLabel,
      manualTree:data.treeManual,
      formData:data.formData,
      company:data.company,
      _id:data._id})
  }

  // const profile2 = context.profileState
  // let isAdmin = profile2.role === 'admin';

  var data2 = []
  const [rows2,setRows2] = React.useState([])
  const abortControll = new AbortController()
  const signal = abortControll.signal
  const [loading,setLoading] = React.useState(true)

  const adminFetch = async () =>{
    try {
      await fetch('/AdminReportList',{signal:signal})
      .then(res=>res.json())
      .then(j=>{
          j.map(r=>{
              // console.log(r)
              data2.push(r)
              return 0
          })
      })

      const rowd = []
      data2.map(x=>{
        // console.log(x.formData.productName)
        
        let date = x.createDate.split('T')[0].split('-')
        let dateFormat = date[2]+"/"+date[1]+"/"+date[0]
      rowd.push(createData(x.formData.productName,x.formData.model,dateFormat,x.company,"กดเพื่อดูผล"
      ,x._id,x.userLabelAnswer,x.userManualAnswer,x.treeLabel,x.treeManual,x.formData))
        return 0;
      })
      setRows2(rowd)
      console.log(rowd)
    } catch (error) {
      console.log('user abort')
    }
    setLoading(false)
    // console.log(rowd)
  }

    ///ReportList/:Id
    const userFetch = async () =>{
      try {
        await fetch(`/ReportList/${id}`,{
        signal:signal,
        headers : { 
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
        })
        .then(res=>{
          return res.json()
        })
        .then(j=>{
          // console.log(j)
            j.map(r=>{
                // console.log(r)
                data2.push(r)
                return 0
            })
        })
        const rowd = []
        data2.map(x=>{
          // console.log(x.formData.productName)
          
          let date = x.createDate.split('T')[0].split('-')
          let dateFormat = date[2]+"/"+date[1]+"/"+date[0]
        rowd.push(createData(x.formData.productName,x.formData.model,dateFormat,x.company,"กดเพื่อดูผล"
        ,x._id,x.userLabelAnswer,x.userManualAnswer,x.treeLabel,x.treeManual,x.formData))
        return 0;
        })
        setRows2(rowd)
      } catch (error) {
        console.log('user abort')
      }
      setLoading(false)
      // console.log(rowd)
    }

  React.useEffect(()=>{
    if(role==='admin'){
      //globalfetch
      adminFetch()
      return function cleanup(){
        abortControll.abort()
      }
    }else{
      //localfetch
      if(id){
        userFetch()
        return function cleanup(){
          abortControll.abort()
        }
      }
        
      // console.log('user SAD')
    }
  },[role])

  React.useEffect(()=>{
    // console.log(rows2)
  },[rows2])
  const scrollTop = () =>{
    window.scrollTo({top: 0, behavior: 'smooth'});
 };
 const scrollBot = () =>{
    //window.scrollTo({bottom: 0, behavior: 'smooth'});
    divRref.current.scrollIntoView({ behavior: 'smooth' });
 };
  const [goTo,setGoto] = React.useState('go to Bottom')
  // const checkScrollTop = () => {
  //       if (window.pageYOffset > 400){
  //           setGoto('go to Top')
  //       } else if (window.pageYOffset <= 400){
  //           setGoto('go to Bottom')
  //       }
  // };
  
  const divRref = React.useRef(null);
  const divRef = React.createRef();
  const [open, setOpen] = React.useState(false);
  const [idToremove,setIdToremove] = React.useState('')
  const handleClickOpen = (row) => {
    setOpen(true);
    setIdToremove(row._id)
  };

  const handleClose = () => {
    setOpen(false);
    setIdToremove('')
  };
  const handleRemoveReport = () => {
    fetch(`/removeReport/${idToremove}`, {
        method: 'DELETE',
        headers: new Headers({
        'Content-Type': 'application/json'
        })
    })
    setRows2(rows2.filter(item => item._id !== idToremove))
    console.log(rows2)
    handleClose()
  }
  

        // window.addEventListener('scroll', checkScrollTop)
 React.useEffect(
   function setupListener() {
          const checkScrollTop = () => {
              
              if (window.pageYOffset > 400){
                  setGoto('go to Top')
              } else if (window.pageYOffset <= 400){
                  setGoto('go to Bottom')
              }
          };
          window.addEventListener('scroll', checkScrollTop)
      
          return function cleanup() {
            window.removeEventListener('scroll', checkScrollTop)

          }

  }
  
  )
  return (
    <div style={{marginBottom:'90px'}}>
      
      <div style={{padding:'80px',paddingTop:'40px',paddingBottom:0}}>
        <Button variant="contained" color="primary" onClick={goTo==='go to Bottom'?scrollBot:scrollTop} style={{ position:'fixed',width:'20px',height:'30px',bottom:'100px',right:'30px'}}>
                 {goTo==='go to Top'?
                     <ArrowUpwardIcon style={{width:'20px',height:'20px'}}/>
                 :
                    <ArrowDownwardIcon style={{width:'20px',height:'20px'}}/>
                 }
        </Button>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="stretch"
        
      >
          <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
            <p style={{marginRight:'10px'}}>ประเมินผลิตภัณฑ์</p>
            <Button variant='contained' color='primary' onClick={()=>{
              props.history.push('/assesment', { id: 1})
            }}>เพิ่มแบบประเมินตนเอง</Button>
          </div>
          <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
            <label htmlFor="search">ค้นหา</label>
            <TextField style={{marginLeft:'5px'}} variant="outlined" size="small" type="text" name="search" id="search" onChange={(e)=>{setSearch(e.target.value)}} value={search} placeholder="ชื่อบริษัท/ชื่อผลิตภัณฑ์"></TextField>
            {/* <button>ดาวน์โหลด</button> */}
          </div>
        </Grid>
      </div>
    
    {
      
      <div style={{padding:'5px',paddingTop:'5px',paddingBottom:'5px',marginLeft:'80px',marginRight:'80px',backgroundColor:'#F3A492',borderRadius:'5px'}}>
        <TableContainer component={Paper} style={{minWidth:'759px'}}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow style={{backgroundColor:''}} >
                <TableCell style={{width:'50px'}}>ลำดับ</TableCell>
                <TableCell style={{width:'100px'}} align="left">ชื่อผลิตภัณฑ์</TableCell>
                <TableCell style={{width:'100px'}} align="left">รุ่น</TableCell>
                <TableCell style={{width:'150px'}} align="left">วันที่สร้างแบบประเมิน</TableCell>
                <TableCell style={{width:'300px'}} align="left">ชื่อบริษัท</TableCell>
                <TableCell style={{width:'100px'}} align="left">รายงานผล</TableCell>
                <TableCell style={{width:'100px'}} align="left"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody >
              {
              loading?
                <TableRow >
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                    <Lottie options={defaultOptions} height={80} width={80} />
                  
                </TableRow>
              :
              rows2.filter((data)=>{
                if(search === null|| search ==='')
                    return data
                if(data.company.toLowerCase().includes(search.toLowerCase())||data.productName.toLowerCase().includes(search.toLowerCase())){
                    return data
                }
              }).map((row,index) => (
                <TableRow key={`r-${index}`} onClick={(e)=>{
                                                // handleRowClick(row)
                                                // console.log(e.target.id)
                                                if(e.target.tagName==='svg'||e.target.tagName==='BUTTON'||e.target.tagName==='path'){
                                                  handleClickOpen(row)
                                                }
                                                else{
                                                  // console.log(e.target.tagName)
                                                  handleRowClick(row)

                                                }
                                              }
                                            } hover={true} style={{backgroundColor:(index%2)===0?'#F3EFEF':''}}  className={classes.tableRow} >
                  <TableCell component="th" scope="row">{index+1}</TableCell>
                  <TableCell align="left">{row.productName}</TableCell>
                  <TableCell align="left">{row.model}</TableCell>
                  <TableCell align="left">{row.createDate}</TableCell>
                  <TableCell align="left">{row.company}</TableCell>
                  <TableCell align="left"><div style={{cursor:'pointer',textDecorationLine:'underline'}}>{row.result}</div></TableCell>
                  <TableCell align="left"><IconButton id='svg' className={classes.remove} aria-label="delete">
                                            <DeleteIcon id='path'  style={{color:'#3D3C3C'}}/>
                                          </IconButton></TableCell>
                                          
                </TableRow>
                
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    }
    <Dialog ref={divRef}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"คุณต้องการลบ ผลการประเมินนี้?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>{handleClose()}} color="primary">
            ไม่
          </Button>
          <Button onClick={()=>{handleRemoveReport()}} color="primary" autoFocus>
            ใช่
          </Button>
        </DialogActions>
      </Dialog>
    <div ref={divRref}></div>
    
    </div>
  );
}
